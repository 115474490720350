import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Spinner} from 'react-bootstrap';
import {
  MonthlyIncidentSummaryInterface,
  IncidentSummaryInterface,
  IncidentImpact,
} from './IncidentSummaryContainer';
import IncidentSummaryList from '../../components/IncidentSummaryList';
import IncidentSummaryMenu from '../../components/IncidentSummaryMenu';
import {CustomDropdownData} from '../../components/CustomDropdown';
import '../../assets/sass/incidents.scss';
import {EventType, IncidentSummaryPage} from '../../constants';
import {postAnalytics} from '../../lib/analytics/postAnalytics';

interface IncidentSummaryProps {
  monthlyIncidentSummaries: MonthlyIncidentSummaryInterface[];
  regionList: CustomDropdownData[];
  serviceList: CustomDropdownData[];
  onMonthChange: any;
  isLoading: boolean;
}

const IncidentSummary = (props: IncidentSummaryProps) => {
  const {t} = useTranslation();
  const allString = t('Common.all');
  const [selectedRegion, setSelectedRegion] = useState(allString);
  const [selectedService, setSelectedService] = useState(allString);
  const [monthlyIncidentSummaries, setMonthlyIncidentSummaries] = useState(
    props.monthlyIncidentSummaries,
  );

  useEffect(() => {
    const filteredIncidentSummaries: MonthlyIncidentSummaryInterface[] = [];
    props.monthlyIncidentSummaries.forEach(
      (monthlyIncidentSummary: MonthlyIncidentSummaryInterface) => {
        const filteredIncidents: IncidentSummaryInterface[] = [];
        monthlyIncidentSummary.incidentSummaries.forEach(
          (incidentSummary: IncidentSummaryInterface) => {
            const regions = new Set();
            const services = new Set();
            incidentSummary.incidentImpacts &&
              incidentSummary.incidentImpacts.forEach(
                (impact: IncidentImpact) => {
                  regions.add(impact.regionName);
                  services.add(impact.serviceName);
                },
              );
            if (
              (selectedRegion === allString || regions.has(selectedRegion)) &&
              (selectedService === allString || services.has(selectedService))
            ) {
              filteredIncidents.push(incidentSummary);
            }
          },
        );
        filteredIncidentSummaries.push({
          month: monthlyIncidentSummary.month,
          incidentSummaries: filteredIncidents,
        });
      },
    );
    setMonthlyIncidentSummaries(filteredIncidentSummaries);
  }, [props.monthlyIncidentSummaries, selectedRegion, selectedService]);

  const onRegionChange = (regionName: string) => {
    setSelectedRegion(regionName);
    postAnalytics(
      `${IncidentSummaryPage.regionCategoryClick} - ${regionName}`,
      EventType.ClickEvent,
    );
  };

  const onServiceChange = (serviceName: string) => {
    setSelectedService(serviceName);
    postAnalytics(
      `${IncidentSummaryPage.serviceCategoryClick} - ${serviceName}`,
      EventType.ClickEvent,
    );
  };

  return (
    <div className="incidents">
      <IncidentSummaryMenu
        onServiceChange={onServiceChange}
        onRegionChange={onRegionChange}
        regionList={props.regionList}
        serviceList={props.serviceList}
        onMonthChange={props.onMonthChange}
      />
      {props.isLoading ? (
        <div className="loading-spinner">
          <Spinner animation="border" />
        </div>
      ) : (
        <IncidentSummaryList
          monthlyIncidentSummaries={
            monthlyIncidentSummaries
          }></IncidentSummaryList>
      )}
    </div>
  );
};

export default IncidentSummary;
