export const Application = {
  load: 'Application Loaded',
};

export const StatusPage = {
  load: 'Status Dashboard',
  regionCategoryClick: 'Region Category',
  viewIncidentClick: 'View Incident',
  render: 'Render Status Page',
  fetchJSON: 'StatusPage - Fetch Components JSON',
};

export const IncidentSummaryPage = {
  load: 'Incident Summary',
  incidentDetailClick: 'View Incident Details',
  regionCategoryClick: 'Region Category Filter',
  serviceCategoryClick: 'Service Category Filter',
  monthFilterClick: 'Date Range Filter',
  render: 'Render Incident Summary Page',
  fetchSummaryJSON: 'IncidentSummaryPage - Fetch Incident Summary JSON',
  fetchComponentsJSON: 'IncidentSummaryPage - Fetch Components JSON',
};

export const IncidentDetailPage = {
  load: 'Incident Detail',
  render: 'Render Incident Detail Page',
  fetchJSON: 'IncidentDetailPage - Fetch Incident Detail JSON',
};

export const ErrorPage = {
  load: 'Error Page',
  tryAgainClick: 'Try Again',
  contactSupportClick: 'Contact Support',
  copyClick: 'Copy Error Code',
  reload: 'Reload',
  consoleAnnouncements: 'Console Announcements',
  render: 'Render Error Page',
};

export const Subscription = {
  rssClick: 'RSS',
};

export const Header = {
  liveStatsClick: 'Live Stats',
  incidentHistoryClick: 'Incident History',
};

export const SystemStatus = {
  fetchJSON: 'StatusPage - Fetch Status JSON',
};
