import React from 'react';
import {useTranslation} from 'react-i18next';
import MonthFilter from './MonthFilter';
import '../assets/sass/incidents.scss';
import CustomDropdown, {CustomDropdownData} from './CustomDropdown';

interface IncidentSummaryMenuProps {
  regionList: CustomDropdownData[];
  serviceList: CustomDropdownData[];
  onRegionChange: any;
  onServiceChange: any;
  onMonthChange: any;
}

const IncidentSummaryMenu = (props: IncidentSummaryMenuProps) => {
  const onChangeRegionSelect = (value: string) => {
    props.onRegionChange(value);
  };

  const onChangeServiceSelect = (value: string) => {
    props.onServiceChange(value);
  };

  const {t} = useTranslation();

  return (
    <div className="incident-summary-menu">
      <h2>{t('Incident.historyPageHeading')}</h2>
      <div className="incident-summary-filter-group">
        <div className="incident-summary-menu-filter mr-15">
          <div className="incident-summary-menu-label">
            {t('Incident.regionSelectLabel')}:
          </div>
          <CustomDropdown
            dataTestid="region-filter"
            data={props.regionList}
            onValueChange={onChangeRegionSelect}
          />
        </div>
        <div className="incident-summary-menu-filter mr-15">
          <div className="incident-summary-menu-label">
            {t('Incident.serviceSelectLabel')}:
          </div>
          <CustomDropdown
            dataTestid="service-filter"
            data={props.serviceList}
            onValueChange={onChangeServiceSelect}
          />
        </div>
        <MonthFilter
          className="incident-summary-menu-filter"
          onChange={props.onMonthChange}
        />
      </div>
    </div>
  );
};

export default IncidentSummaryMenu;
