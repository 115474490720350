import React from 'react';
import {StatusSeverityOrder} from '../constants/enums';
import {IncidentImpact} from '../pages/incident-summary/IncidentSummaryContainer';
import IncidentImpactListItem from './IncidentImpactListItem';

interface IncidentImpactListProps {
  incidentImpacts: IncidentImpact[];
}

export interface FilteredIncidentImpact {
  status?: string;
  serviceName: string;
  regions: Set<string>;
}

const parseIncidentImpacts = (incidentImpacts: IncidentImpact[]) => {
  const filteredIncidentImpacts: FilteredIncidentImpact[] = [];
  incidentImpacts.forEach((incidentImpact) => {
    const index = filteredIncidentImpacts.findIndex(
      (filteredIncidentImpact) => {
        return (
          filteredIncidentImpact.serviceName === incidentImpact.serviceName &&
          filteredIncidentImpact.status === incidentImpact.impactStatus
        );
      },
    );
    if (index === -1) {
      const regions: Set<string> = new Set();
      regions.add(incidentImpact.regionName);
      filteredIncidentImpacts.push({
        status: incidentImpact.impactStatus,
        serviceName: incidentImpact.serviceName,
        regions: regions,
      });
    } else {
      const foundIncidentImpact = filteredIncidentImpacts[index];
      foundIncidentImpact.regions.add(incidentImpact.regionName);
    }
  });
  filteredIncidentImpacts.sort(
    (a, b) =>
      StatusSeverityOrder.indexOf(a.status ? a.status : '') -
        StatusSeverityOrder.indexOf(b.status ? b.status : '') ||
      a.serviceName.localeCompare(b.serviceName),
  );
  return filteredIncidentImpacts;
};

const IncidentImpactList = (props: IncidentImpactListProps) => {
  const incidentImpacts = parseIncidentImpacts(props.incidentImpacts);
  return (
    <div>
      {incidentImpacts &&
        incidentImpacts.map(
          (incidentImpact: FilteredIncidentImpact, index: number) => {
            return (
              <IncidentImpactListItem
                key={`incident-impact-item-${index}`}
                incidentImpact={incidentImpact}
              />
            );
          },
        )}
    </div>
  );
};

export default IncidentImpactList;
