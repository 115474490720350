import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {IncidentDetailInterface} from '../pages/incident-detail/IncidentDetailContainer';
import {
  getIncidentDetail,
  getSecondaryIncidentDetail,
} from '../api/incidentsApi';
import IncidentImpactList from './IncidentImpactList';
import {infoIcon} from '../assets/icons';
import {EventType, Pages, ClientUrls, StatusPage} from '../constants';
import {postAnalytics} from '../lib/analytics/postAnalytics';
import {getUTCDateTimeString} from '../util/Date';
import {getEnableHeartbeatDetectionFlag} from '../util/config';

const ActiveIncident = ({incidentId}: {incidentId: string}) => {
  const initialData: IncidentDetailInterface = {
    incidentId: '',
    status: '',
    title: '',
    referenceTicketNumber: '',
    parentTicketNumber: '',
    incidentPosts: [],
    backFilled: false,
  };
  const enableHeartbeatDetectionFlag = getEnableHeartbeatDetectionFlag();
  const [activeIncident, setActiveIncident] = useState<IncidentDetailInterface>(
    initialData,
  );
  const [isLoading, setIsLoading] = useState(true);
  const {t} = useTranslation();

  function fetchIncidentDetails(fileName: string) {
    getIncidentDetail(fileName)
      .then(
        (data) => {
          if (data) {
            setActiveIncident(data);
          }
        },
        (error) => {
          console.warn('Error while loading data from primary', error);
          if (enableHeartbeatDetectionFlag) {
            getSecondaryIncidentDetail(fileName).then(
              (data) => {
                if (data) {
                  setActiveIncident(data);
                }
              },
              (error) => {
                console.warn('Error while loading data from secondary', error);
              },
            );
          }
        },
      )
      .finally(() => setIsLoading(false));
  }

  const onViewIncidentClick = (event: any) => {
    postAnalytics(
      `${StatusPage.viewIncidentClick} - ${event.target.id}`,
      EventType.ClickEvent,
    );
  };

  useEffect(() => {
    fetchIncidentDetails(incidentId);
  }, [incidentId]);

  return (
    <div data-testid="active-incident">
      {(isLoading || activeIncident.incidentId) && (
        <div
          data-testid="active-incident-card"
          className="active-incident-card"
          key={incidentId}>
          {activeIncident && activeIncident.incidentId && (
            <div className="active-incident-component">
              <div className="active-incident-content mr-10">
                <img
                  data-testid="active-incident-info-icon"
                  className="status-icon mr-10"
                  src={infoIcon}
                  alt={t('Incident.activeIncidentInfo')}
                  title={t('Incident.activeIncidentInfo')}
                />
                <div>
                  <Link
                    onClick={onViewIncidentClick}
                    data-testid="view-incident-link"
                    to={{
                      pathname: `${ClientUrls.incidentDetailPage}${incidentId}`,
                      state: {parentPage: Pages.LiveStats},
                    }}>
                    <h3>{activeIncident.title}</h3>
                  </Link>
                  {activeIncident.incidentPosts &&
                    activeIncident.incidentPosts[0] && (
                      <div>
                        <p>{activeIncident.incidentPosts[0].message}</p>
                        <p>
                          {getUTCDateTimeString(
                            activeIncident.incidentPosts[0].incidentStartTime,
                          )}
                        </p>
                        <IncidentImpactList
                          incidentImpacts={
                            activeIncident.incidentPosts[0].incidentImpacts
                          }
                        />
                      </div>
                    )}
                </div>
              </div>

              <Link
                className="button-secondary"
                onClick={onViewIncidentClick}
                data-testid="view-incident-link"
                to={{
                  pathname: `${ClientUrls.incidentDetailPage}${incidentId}`,
                  state: {parentPage: Pages.LiveStats},
                }}>
                {t('Incident.viewIncident')}
              </Link>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ActiveIncident;
