import React, {useEffect, useState} from 'react';
import {Dropdown, Form} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {expandIcon, searchIcon, minimizeIcon} from '../assets/icons';
import '../assets/sass/customDropdown.scss';

interface DropdownValue {
  id: string;
  value: string;
  active?: boolean;
}

export interface CustomDropdownData {
  id: string;
  category: string;
  expanded?: boolean;
  values: DropdownValue[];
}

export interface CustomDropdownProps {
  data: CustomDropdownData[];
  onValueChange?: any;
  dataTestid?: any;
}

const CustomDropdown = (props: CustomDropdownProps) => {
  const {t} = useTranslation();
  const allConstant = t('Common.all');
  const [dropdownValue, setDropdownValue] = useState(allConstant);
  const [data, setData] = useState<CustomDropdownData[]>([]);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  // Function to be called when an option is selected
  const onOptionClick = (event: any) => {
    setDropdownValue(event.target.textContent);
    props.onValueChange(event.target.textContent);
  };

  // Function to toggle header menu
  const toggleHeaderMenu = (id: string) => {
    const updatedData: CustomDropdownData[] = [];
    data.forEach((category) => {
      if (category.id === id) {
        category.expanded = !category.expanded;
      }
      updatedData.push(category);
    });
    setData(updatedData);
  };

  // Function to be called when option header is selected
  const onOptionHeaderClick = (event: any) => {
    toggleHeaderMenu(event.target.id);
  };

  // Function to be called when text is entered in the search box
  const onInputChange = (event: any) => {
    setInputValue(event.target.value);
    const searchText = event.target.value.toLowerCase();
    const updatedData: CustomDropdownData[] = [];
    let elementFound = false;
    let maximizeElement = false;

    data.forEach((category) => {
      const updatedValues: DropdownValue[] = [];
      category.values.forEach((value) => {
        if (
          searchText &&
          !elementFound &&
          value.value.toLowerCase().includes(searchText)
        ) {
          elementFound = true;
          maximizeElement = true;
          value.active = true;
        } else {
          value.active = false;
        }
        updatedValues.push(value);
      });
      if (elementFound && maximizeElement) {
        category.expanded = true;
        maximizeElement = false;
      } else {
        category.expanded = false;
      }
      category.values = updatedValues;
      updatedData.push(category);
    });
    setData(updatedData);
  };

  // Function to be called when menu is hidden or opened
  const onToggle = () => {
    setInputValue('');
    setData(props.data);
  };

  return (
    <div data-testid={props.dataTestid}>
      <Dropdown className="custom-dropdown" onToggle={onToggle}>
        <Dropdown.Toggle
          data-testid="filter-text"
          className="custom-dropdown-toggle">
          {dropdownValue}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <div className="custom-dropdown-search-bar">
            <img
              src={searchIcon}
              className="custom-dropdown-search-icon"
              alt="search"
            />
            <Form.Control
              as="input"
              value={inputValue}
              className="custom-dropdown-search-input"
              onChange={onInputChange}></Form.Control>
          </div>
          <Dropdown.Item
            className="custom-dropdown-item"
            as="button"
            onClick={onOptionClick}
            key={allConstant}
            eventKey={allConstant}
            active={false}>
            {allConstant}
          </Dropdown.Item>
          {data &&
            data.map((category) => (
              <div key={category.category}>
                <Dropdown.Header
                  data-testid="filter-header"
                  className="custom-dropdown-category"
                  id={category.id}
                  onClick={onOptionHeaderClick}>
                  {category.expanded ? (
                    <img
                      id={category.id}
                      className="custom-dropdown-category-image"
                      src={minimizeIcon}
                      alt="minimize"
                    />
                  ) : (
                    <img
                      id={category.id}
                      className="custom-dropdown-category-image"
                      src={expandIcon}
                      alt="expand"
                    />
                  )}
                  {category.category}
                </Dropdown.Header>
                {category &&
                  category.expanded &&
                  category.values &&
                  category.values.map((value) => (
                    <Dropdown.Item
                      data-testid="filter-option"
                      className="custom-dropdown-item"
                      as="button"
                      onClick={onOptionClick}
                      key={value.id}
                      eventKey={value.id}
                      active={value.active}>
                      {value.value}
                    </Dropdown.Item>
                  ))}
              </div>
            ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default CustomDropdown;
