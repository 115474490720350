export const serviceDisruption = require('./alert-circle.svg').default;
export const operational = require('./success.svg').default;
export const allOperational = require('./all-operational.svg').default;
export const serviceDown = require('./red-alert.svg').default;
export const informational = require('./warning.svg').default;
export const leftArrow = require('./left-arrow.svg').default;
export const rightArrow = require('./right-arrow.svg').default;
export const downArrow = require('./down-arrow.svg').default;
export const searchIcon = require('./search.svg').default;
export const expandIcon = require('./expand.svg').default;
export const minimizeIcon = require('./minimize.svg').default;
export const legendIcon = require('./legend.svg').default;
export const chevronRight = require('./chevron-right.svg').default;
export const infoIcon = require('./info.svg').default;
export const serviceNotAvailable = require('./service-not-available.svg')
  .default;
