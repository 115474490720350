import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {useTranslation} from 'react-i18next';
import {Spinner} from 'react-bootstrap';
import BreadCrumb, {BreadCrumbItem} from '../../components/BreadCrumb';
import IncidentDetail from './IncidentDetail';
import {IncidentImpact} from '../incident-summary/IncidentSummaryContainer';
import {
  getIncidentDetail,
  getSecondaryIncidentDetail,
} from '../../api/incidentsApi';
import {EventStatus, EventType, IncidentDetailPage} from '../../constants';
import {postAnalytics} from '../../lib/analytics/postAnalytics';
import {Pages, ClientUrls} from '../../constants';
import {getEnableHeartbeatDetectionFlag} from '../../util/config';
import {MetricsWrapper} from '../../components/MetricsWrapper';
import ErrorBoundary from '../../components/ErrorBoundary';

export interface IncidentUpdate {
  incidentPostId: string;
  message: string;
  customerImpact: string;
  rootCause: string;
  status: string;
  incidentStartTime: string;
  incidentEndTime: string;
  lastModifiedTime: string;
  createdTime: string;
  postedTime: string;
  incidentImpacts: IncidentImpact[];
}

export interface IncidentDetailInterface {
  incidentId: string;
  status: string;
  title: string;
  referenceTicketNumber?: string;
  parentTicketNumber?: string;
  incidentPosts: IncidentUpdate[];
  backFilled: boolean;
}

export interface IncidentDetailParams {
  incidentId: string;
}

const IncidentDetailContainer = ({
  updatePageHeader,
  location,
}: {
  updatePageHeader?: any;
  location?: {state?: any};
}) => {
  const {t} = useTranslation();
  const enableHeartbeatDetectionFlag = getEnableHeartbeatDetectionFlag();
  const params: IncidentDetailParams = useParams();
  const incidentId = params.incidentId;
  const initialData: IncidentDetailInterface = {
    incidentId: '',
    status: '',
    title: '',
    referenceTicketNumber: '',
    parentTicketNumber: '',
    incidentPosts: [],
    backFilled: false,
  };
  const [incidentDetails, setIncidentDetails] = useState(initialData);
  const [isLoading, setIsLoading] = useState(true);

  const fetchIncidentDetails = (fileName: string) => {
    getIncidentDetail(fileName)
      .then(
        (data) => {
          if (data) {
            setIncidentDetails(data);
            postAnalytics(IncidentDetailPage.fetchJSON, EventStatus.Success, {
              httpStatusCode: 200,
            });
          }
        },
        (error) => {
          console.warn('Error while loading data from primary path', error);
          if (enableHeartbeatDetectionFlag) {
            getSecondaryIncidentDetail(fileName).then(
              (data) => {
                if (data) {
                  setIncidentDetails(data);
                  postAnalytics(
                    IncidentDetailPage.fetchJSON,
                    EventStatus.Success,
                    {httpStatusCode: 200},
                  );
                }
              },
              (error) => {
                console.warn(
                  'Error while loading data from secondary path',
                  error,
                );
                postAnalytics(
                  IncidentDetailPage.fetchJSON,
                  EventStatus.Failure,
                  {httpStatusCode: error?.httpStatusCode},
                );
              },
            );
          } else {
            postAnalytics(IncidentDetailPage.fetchJSON, EventStatus.Failure, {
              httpStatusCode: error?.httpStatusCode,
            });
          }
        },
      )
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    updatePageHeader && updatePageHeader('incident-detail');
    fetchIncidentDetails(incidentId);
    postAnalytics(IncidentDetailPage.load, EventType.PageViewEvent);
  }, [incidentId, updatePageHeader]);

  const createBreadCrumb = (parentPage?: string) => {
    let previousPage: BreadCrumbItem = {
      displayName: t('Header.viewLiveStatus'),
      href: ClientUrls.homePage,
    };

    if (parentPage) {
      switch (parentPage) {
        case Pages.IncidentHistory:
          previousPage.displayName = t('Header.incidentHistory');
          previousPage.href = ClientUrls.incidentHistoryPage;
          break;
        case Pages.LiveStats:
          previousPage.displayName = t('Header.viewLiveStatus');
          previousPage.href = ClientUrls.homePage;
          break;
      }
    }
    const breadcrumbItems: BreadCrumbItem[] = [
      previousPage,
      {
        displayName: t('Header.incidentDetail'),
      },
    ];

    return breadcrumbItems;
  };

  const breadcrumbItems: BreadCrumbItem[] =
    location && location.state && location.state.parentPage
      ? createBreadCrumb(location.state.parentPage)
      : createBreadCrumb();

  const handleError = () => {
    postAnalytics(IncidentDetailPage.render, EventStatus.Failure);
  };

  return (
    <ErrorBoundary onError={handleError}>
      <MetricsWrapper
        eventName={IncidentDetailPage.render}
        eventStatus={EventStatus.Success}>
        <div
          data-testid="incident-detail-page"
          className="p-0 app d-flex flex-column">
          <BreadCrumb items={breadcrumbItems} />
          <div className="page-content-container">
            <div className="content-header-top"></div>
            {isLoading ? (
              <div className="loading-spinner">
                <Spinner animation="border" />
              </div>
            ) : (
              <IncidentDetail incidentDetails={incidentDetails} />
            )}
          </div>
        </div>
      </MetricsWrapper>
    </ErrorBoundary>
  );
};

export default IncidentDetailContainer;
