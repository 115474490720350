import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {leftArrow, rightArrow} from '../assets/icons';
import {getParsedMonth, getInitialDates} from '../util/Date';

interface MonthFilterProps {
  start?: Date;
  end?: Date;
  className: string;
  onChange?: any;
}

const MonthFilter = (props: MonthFilterProps) => {
  const {t} = useTranslation();
  const [startDate, endDate] = getInitialDates(props.start, props.end);
  const [dates, setDates] = useState({
    start: startDate,
    end: endDate,
  });

  const onClickLeftArrow = () => {
    const start: Date = new Date(dates.start);
    const end: Date = new Date(dates.end);
    const limit: Date = new Date();
    limit.setHours(0, 0, 0, 0);
    limit.setFullYear(limit.getFullYear() - 5);
    if (limit <= start) {
      start.setMonth(start.getMonth() - 3);
      end.setMonth(end.getMonth() - 3);
      setDates({
        start: start,
        end: end,
      });
      props.onChange(new Date(start), new Date(end));
    }
  };

  const onClickRightArrow = () => {
    const start: Date = new Date(dates.start);
    const end: Date = new Date(dates.end);
    const today: Date = new Date();
    today.setDate(1);
    today.setHours(0, 0, 0, 0);
    if (today > end) {
      start.setMonth(start.getMonth() + 3);
      end.setMonth(end.getMonth() + 3);
      setDates({
        start: start,
        end: end,
      });
      props.onChange(new Date(start), new Date(end));
    }
  };

  return (
    <div className={`${props.className} month-filter`}>
      <div className="month-filter-label">
        {t('MonthFilter.monthFilterLabel')}
      </div>
      <div
        data-testid="month-filter-left"
        onClick={onClickLeftArrow}
        className="month-filter-arrow d-flex flex-column align-items-center">
        <img src={leftArrow} alt={t('MonthFilter.previousMonths')} />
      </div>
      <div className="month-filter-text" data-testid="month-filter-text">
        {getParsedMonth(dates.start)} to {getParsedMonth(dates.end)}
      </div>
      <div
        data-testid="month-filter-right"
        onClick={onClickRightArrow}
        className="month-filter-arrow d-flex flex-column align-items-center">
        <img src={rightArrow} alt={t('MonthFilter.nextMonths')} />
      </div>
    </div>
  );
};

export default MonthFilter;
