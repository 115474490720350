import React, {useState, useEffect} from 'react';
import '../../assets/sass/incidents.scss';
import IncidentSummary from './IncidentSummary';
import {
  EventStatus,
  EventType,
  IncidentSummaryPage,
  Pages,
} from '../../constants';
import {getInitialDates, getParsedMonth} from '../../util/Date';
import {
  RegionServiceStatus,
  Service,
} from '../status-dashboard/StatusDashboardContainer';
import {
  getIncidentSummary,
  getSecondaryIncidentSummary,
} from '../../api/incidentsApi';
import {getSecondaryStatusSummary, getStatusSummary} from '../../api/statusApi';
import {CustomDropdownData} from '../../components/CustomDropdown';
import {postAnalytics} from '../../lib/analytics/postAnalytics';
import {getEnableHeartbeatDetectionFlag} from '../../util/config';
import {MetricsWrapper} from '../../components/MetricsWrapper';
import ErrorBoundary from '../../components/ErrorBoundary';

export interface MonthlyIncidentSummaryInterface {
  month: string;
  incidentSummaries: IncidentSummaryInterface[];
}

export interface IncidentImpact {
  regionName: string;
  serviceCategory: string;
  serviceName: string;
  impactStatus?: string;
}

export interface IncidentSummaryInterface {
  incidentId: string;
  title: string;
  message: string;
  customerImpact: string;
  rootCause: string;
  incidentStartTime: string;
  incidentEndTime: string;
  firstIncidentPostTime: string;
  lastIncidentPostTime: string;
  incidentImpacts: IncidentImpact[];
}

const IncidentSummaryContainer = ({
  updatePageHeader,
}: {
  updatePageHeader?: any;
}) => {
  const [monthlyIncidentSummaries, setMonthlyIncidentSummaries] = useState<
    MonthlyIncidentSummaryInterface[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);
  const enableHeartbeatDetectionFlag = getEnableHeartbeatDetectionFlag();

  async function readIncidentSummaries(fileName: string) {
    let monthlyData: IncidentSummaryInterface[] = [];
    await getIncidentSummary(fileName).then(
      (data) => {
        monthlyData = data.incidentSummaries;
        postAnalytics(
          IncidentSummaryPage.fetchSummaryJSON,
          EventStatus.Success,
          {
            httpStatusCode: 200,
          },
        );
      },
      async (error) => {
        console.warn('Error while loading data in primary path', error);
        if (enableHeartbeatDetectionFlag) {
          await getSecondaryIncidentSummary(fileName).then(
            (data) => {
              monthlyData = data.incidentSummaries;
              postAnalytics(
                IncidentSummaryPage.fetchSummaryJSON,
                EventStatus.Success,
                {
                  httpStatusCode: 200,
                },
              );
            },
            (error) => {
              console.warn('Error while loading data in secondary path', error);
              postAnalytics(
                IncidentSummaryPage.fetchSummaryJSON,
                EventStatus.Failure,
                {
                  httpStatusCode: error?.httpStatusCode,
                },
              );
            },
          );
        } else {
          postAnalytics(
            IncidentSummaryPage.fetchSummaryJSON,
            EventStatus.Failure,
            {
              httpStatusCode: error?.httpStatusCode,
            },
          );
        }
      },
    );
    return monthlyData;
  }

  const parseRegionsServices = (regions: RegionServiceStatus[]) => {
    const regionCategoryList: CustomDropdownData[] = [];
    const serviceCategoryList: CustomDropdownData[] = [];
    regions.forEach((region: RegionServiceStatus) => {
      // Create region list for filter
      const regionIndex = regionCategoryList.findIndex(
        (regionCategory) =>
          regionCategory.category === region.geographicAreaName,
      );
      if (regionIndex === -1) {
        regionCategoryList.push({
          id: region.geographicAreaName,
          category: region.geographicAreaName,
          values: [{id: region.regionId, value: region.regionName}],
        });
      } else {
        const regionCategory = regionCategoryList[regionIndex];
        if (
          !regionCategory.values.some(
            (element) => region.regionId === element.id,
          )
        ) {
          regionCategory.values.push({
            id: region.regionId,
            value: region.regionName,
          });
        }
      }
      // Create service list for filter
      region.serviceHealthReports.forEach((service: Service) => {
        const serviceIndex = serviceCategoryList.findIndex(
          (serviceCategory) =>
            serviceCategory.category === service.serviceCategoryName,
        );
        if (serviceIndex === -1 && service.serviceCategoryName) {
          serviceCategoryList.push({
            id: service.serviceCategoryName,
            category: service.serviceCategoryName,
            values: [{id: service.serviceId, value: service.serviceName}],
          });
        } else {
          const serviceCategory = serviceCategoryList[serviceIndex];
          if (
            !serviceCategory.values.some(
              (element) => service.serviceId === element.id,
            )
          ) {
            serviceCategory.values.push({
              id: service.serviceId,
              value: service.serviceName,
            });
          }
        }
      });
    });
    regionCategoryList.sort((a, b) => a.category.localeCompare(b.category));
    regionCategoryList.forEach((regionCategory) => {
      regionCategory.values.sort((a, b) => a.value.localeCompare(b.value));
    });
    serviceCategoryList.sort((a, b) => a.category.localeCompare(b.category));
    serviceCategoryList.forEach((serviceCategory) => {
      serviceCategory.values.sort((a, b) => a.value.localeCompare(b.value));
    });
    return {regionCategoryList, serviceCategoryList};
  };

  const updateMonthlyData = async (startDate: Date, endDate: Date) => {
    setIsLoading(true);
    const monthlyIncidentSummaries = [];
    const currentDate = new Date(endDate);

    while (currentDate >= startDate) {
      const monthData = await readIncidentSummaries(
        currentDate.getFullYear().toString() +
          (currentDate.getMonth() + 1).toString().padStart(2, '0'),
      );
      monthlyIncidentSummaries.push({
        month: getParsedMonth(currentDate),
        incidentSummaries: monthData,
      });
      currentDate.setMonth(currentDate.getMonth() - 1);
    }
    setMonthlyIncidentSummaries(monthlyIncidentSummaries);
    setIsLoading(false);
  };

  const onMonthChange = (startDate: Date, endDate: Date) => {
    updateMonthlyData(startDate, endDate);
    postAnalytics(
      `${IncidentSummaryPage.monthFilterClick} - ${getParsedMonth(
        startDate,
      )} to ${getParsedMonth(endDate)}`,
      EventType.ClickEvent,
    );
  };

  const fetchSecondaryStatusData = () => {
    getSecondaryStatusSummary().then(
      (data: {regionHealthReports: RegionServiceStatus[]}) => {
        if (data && data.regionHealthReports) {
          const regionData = parseRegionsServices(data.regionHealthReports);
          setRegions(regionData);
          postAnalytics(
            IncidentSummaryPage.fetchComponentsJSON,
            EventStatus.Success,
            {
              httpStatusCode: 200,
            },
          );
        }
      },
      (error) => {
        console.error('Error while loading data in secondary path', error);
        postAnalytics(
          IncidentSummaryPage.fetchComponentsJSON,
          EventStatus.Failure,
          {
            httpStatusCode: error?.httpStatusCode,
          },
        );
      },
    );
  };

  const fetchStatusData = () => {
    getStatusSummary().then(
      (data: {regionHealthReports: RegionServiceStatus[]}) => {
        if (data && data.regionHealthReports) {
          const regionData = parseRegionsServices(data.regionHealthReports);
          setRegions(regionData);
          postAnalytics(
            IncidentSummaryPage.fetchComponentsJSON,
            EventStatus.Success,
            {
              httpStatusCode: 200,
            },
          );
        }
      },
      (error) => {
        console.error('Error while loading data in primary path', error);
        if (enableHeartbeatDetectionFlag) {
          fetchSecondaryStatusData();
        } else {
          postAnalytics(
            IncidentSummaryPage.fetchComponentsJSON,
            EventStatus.Failure,
            {
              httpStatusCode: error?.httpStatusCode,
            },
          );
        }
      },
    );
  };

  const fetchInitialData = async () => {
    const [startDate, endDate] = getInitialDates();
    updateMonthlyData(startDate, endDate);
  };

  useEffect(() => {
    updatePageHeader && updatePageHeader(Pages.IncidentHistory);
    fetchInitialData();
    postAnalytics(IncidentSummaryPage.load, EventType.PageViewEvent);
  }, []);

  useEffect(() => {
    fetchStatusData();
  }, []);

  const [regions, setRegions] = useState(parseRegionsServices([]));

  const handleError = () => {
    postAnalytics(IncidentSummaryPage.render, EventStatus.Failure);
  };

  return (
    <ErrorBoundary onError={handleError}>
      <MetricsWrapper
        eventName={IncidentSummaryPage.render}
        eventStatus={EventStatus.Success}>
        <div
          data-testid="incident-history-page"
          className="p-0 app d-flex flex-column">
          <div className="page-content-container">
            <div className="content-header-top"></div>
            <IncidentSummary
              isLoading={isLoading}
              monthlyIncidentSummaries={monthlyIncidentSummaries}
              regionList={regions.regionCategoryList}
              serviceList={regions.serviceCategoryList}
              onMonthChange={onMonthChange}
            />
          </div>
        </div>
      </MetricsWrapper>
    </ErrorBoundary>
  );
};

export default IncidentSummaryContainer;
