export const getParsedMonth = (date: Date) => {
  return `${date.toLocaleDateString('default', {
    month: 'long',
  })} ${date.getFullYear()}`;
};

export const getInitialDates = (start?: Date, end?: Date) => {
  const startDate = start ? new Date(start) : new Date();
  const endDate = end ? new Date(end) : new Date();
  startDate.setDate(1);
  endDate.setDate(1);
  startDate.setMonth(startDate.getMonth() - 2);
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(0, 0, 0, 0);
  return [startDate, endDate];
};

export const calculateDaysfromToday = (date: string) => {
  const inputDate: Date = new Date(date);
  const currentDate: Date = new Date();
  inputDate.setHours(0, 0, 0, 0);
  currentDate.setHours(0, 0, 0, 0);
  const difference: number = Math.round(
    (currentDate.valueOf() - inputDate.valueOf()) / (1000 * 3600 * 24),
  );
  return difference;
};

export const getUTCDateTimeString = (dateTime: string) => {
  if (!dateTime) {
    return '';
  }
  return new Date(dateTime).toUTCString().replace('GMT', 'UTC');
};
