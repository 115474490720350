import {APIUrls} from '../constants';
import {getSecondaryDataPath} from '../util/config';
import httpClient from './httpClient';
import {getHeartbeatStatus} from './statusApi';

export const getIncidentSummary = async (fileName: string) => {
  const heartbeatValues = await getHeartbeatStatus();
  const summaryUrl = `${heartbeatValues.basePath}/${APIUrls.incidentSummaryJson}${fileName}.json`;
  return httpClient.get(summaryUrl);
};

export const getSecondaryIncidentSummary = async (fileName: string) => {
  const dataPath = getSecondaryDataPath();
  const summaryUrl = `${dataPath}/${APIUrls.incidentSummaryJson}${fileName}.json`;
  return httpClient.get(summaryUrl);
};

export const getIncidentDetail = async (fileName: string) => {
  const heartbeatValues = await getHeartbeatStatus();
  const detailsUrl = `${heartbeatValues.basePath}/${APIUrls.incidentDetailJson}${fileName}.json`;
  return httpClient.get(detailsUrl);
};

export const getSecondaryIncidentDetail = async (fileName: string) => {
  const dataPath = getSecondaryDataPath();
  const detailsUrl = `${dataPath}/${APIUrls.incidentDetailJson}${fileName}.json`;
  return httpClient.get(detailsUrl);
};
