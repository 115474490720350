import {loomServices} from 'loom-services';

export class SessionMonitor {
  private readonly expiration: number;
  constructor(private maxTime: number) {
    this.expiration = this.currentTime() + maxTime;
  }

  public start() {
    this.refresh();
  }

  private currentTime() {
    return Math.floor(Date.now() / 1000);
  }

  private async refresh() {
    const sessionExp = await loomServices.authService.getSessionExpiration();
    const tokenExpiration = await loomServices.authService.getExpiration();

    console.log(`tokenExpiration ${new Date(tokenExpiration * 1000)}`);

    const timeout = (tokenExpiration - 5 * 60 - this.currentTime()) * 1000;
    console.log(`timeout ${timeout / 60_000} minutes`);

    if (sessionExp > tokenExpiration && this.currentTime() < this.expiration) {
      setTimeout(async () => {
        await loomServices.authService.tryRefresh(true);
        this.refresh();
      }, timeout);
    }
  }
}
