import React from 'react';
import {useTranslation} from 'react-i18next';

const Banner = () => {
  const {t} = useTranslation();

  return (
    <div className="banner">
      <div>{t('Common.bannerHeading')}</div>
    </div>
  );
};
export default Banner;
