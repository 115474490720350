import React, {useEffect, useRef, useState} from 'react';
import {Spinner} from 'react-bootstrap';
import StatusDashboard from './StatusDashboard';
import '../../assets/sass/statusDashboard.scss';
import {
  ErrorPage,
  EventStatus,
  EventType,
  Pages,
  StatusPage,
  StatusSeverityOrder,
} from '../../constants';
import {
  DataPath,
  getHeartbeatStatus,
  getSecondaryStatusSummary,
  getStatusSummary,
} from '../../api/statusApi';
import {postAnalytics} from '../../lib/analytics/postAnalytics';
import ActiveIncident from '../../components/ActiveIncident';
import {useTranslation} from 'react-i18next';
import {
  getAnnouncementsURL,
  getEnableHeartbeatDetectionFlag,
  getErrorDisplayFlag,
} from '../../util/config';
import {StatusHealthFailure} from './StatusHealthFailure';
import {MetricsWrapper} from '../../components/MetricsWrapper';
import ErrorBoundary from '../../components/ErrorBoundary';

//#region types

export interface RegionServiceStatus {
  regionId: string;
  regionName: string;
  geographicAreaName: string;
  serviceHealthReports: Service[];
}

export interface Region {
  regionId: string;
  regionName: string;
}

export interface Service {
  serviceId: NonNullable<string>;
  serviceName: string;
  serviceCategoryName?: string;
  serviceCategoryId?: string;
  serviceStatus?: string;
  incidents?: ServiceIncidents[];
}

interface ServiceIncidents {
  incidentId: string;
  severity: string;
}
export interface ServiceStatus {
  status: string;
  incidentId?: string;
}

export type RegionCategories = Record<string, Region[]>;

export type ServiceCategories = Record<string, Record<string, Service>>;

export type ServiceRegionStatus = Record<string, Record<string, ServiceStatus>>;

const getHighestSeverityIncident = (incidents: ServiceIncidents[]): string =>
  incidents && incidents.length > 0
    ? incidents.sort(
        (a, b) =>
          StatusSeverityOrder.indexOf(a.severity) -
          StatusSeverityOrder.indexOf(b.severity),
      )[0].incidentId
    : '';

//#endregion

const setSummaryStatusData = (statusSummary: {
  regionHealthReports: RegionServiceStatus[];
}) => {
  let regionCategories: RegionCategories = {};
  let serviceCategories: ServiceCategories = {};
  let serviceRegionStatus: ServiceRegionStatus = {};
  let serviceIncidentSet = new Set<string>();
  if (statusSummary?.regionHealthReports) {
    statusSummary.regionHealthReports.forEach((region: RegionServiceStatus) => {
      // set distinct region categories and group regions under region category
      if (!regionCategories[region.geographicAreaName]) {
        regionCategories[region.geographicAreaName] = [];
      }
      regionCategories[region.geographicAreaName].push({
        regionId: region.regionId,
        regionName: region.regionName,
      });

      // set distinct service categories and group services under service category
      region.serviceHealthReports.map((service: Service) => {
        if (
          service.serviceCategoryName &&
          !serviceCategories[service.serviceCategoryName]
        ) {
          serviceCategories[service.serviceCategoryName] = {};
        }
        if (
          service.serviceCategoryName &&
          !serviceCategories[service.serviceCategoryName][service.serviceId]
        ) {
          serviceCategories[service.serviceCategoryName][service.serviceId] = {
            serviceId: service.serviceId,
            serviceName: service.serviceName,
          };
        }
        // set services and status of service in specific region
        if (!serviceRegionStatus[service.serviceId]) {
          serviceRegionStatus[service.serviceId] = {};
        }
        if (!serviceRegionStatus[service.serviceId][region.regionId]) {
          serviceRegionStatus[service.serviceId][region.regionId] = {
            status: service.serviceStatus ?? '',
            incidentId: service.incidents
              ? getHighestSeverityIncident(service.incidents)
              : '',
          };
          service.incidents &&
            service.incidents.forEach((incident: ServiceIncidents) => {
              serviceIncidentSet.add(incident.incidentId);
            });
        }
      });
    });
  }
  return {
    regionCategories,
    serviceCategories,
    serviceRegionStatus,
    serviceIncidentSet,
  };
};

const StatusDashboardContainer = ({
  updatePageHeader,
}: {
  updatePageHeader?: any;
}) => {
  const {t} = useTranslation();
  const enableHeartbeatDetectionFlag = getEnableHeartbeatDetectionFlag();
  const [regionCategories, setRegionCategories] = useState<RegionCategories>(
    {},
  );
  const [serviceCategories, setServiceCategories] = useState<ServiceCategories>(
    {},
  );
  const [
    serviceRegionStatus,
    setServiceRegionStatus,
  ] = useState<ServiceRegionStatus>({});
  const [activeIncidents, setActiveIncidents] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const heartbeat = useRef({
    status: false,
    domain: DataPath.Unknown,
  });
  const [heartbeatFailure, setHeartbeatFailure] = useState(false);
  const [timeUpdated, setTimeUpdated] = useState('');
  const consoleAnnouncementURL = getAnnouncementsURL();
  const showErrorManualFlag = getErrorDisplayFlag();

  const checkHeartbeatHealth = async () => {
    const heartbeatValues = await getHeartbeatStatus();
    return heartbeatValues;
  };

  const fetchSecondaryStatusSummary = () => {
    getSecondaryStatusSummary().then(
      (data: {regionHealthReports: RegionServiceStatus[]}) => {
        const summaryData = setSummaryStatusData(data);
        setRegionCategories(summaryData.regionCategories);
        setServiceCategories(summaryData.serviceCategories);
        setServiceRegionStatus(summaryData.serviceRegionStatus);
        setActiveIncidents(Array.from(summaryData.serviceIncidentSet));
        setIsError(false);
        postAnalytics(StatusPage.fetchJSON, EventStatus.Success, {
          httpStatusCode: 200,
        });
      },
      (error) => {
        console.warn('Error while loading data', error);
        setIsError(true);
        postAnalytics(StatusPage.fetchJSON, EventStatus.Failure, {
          httpStatusCode: error?.httpStatusCode,
        });
      },
    );
  };

  const fetchStatusSummary = () => {
    getStatusSummary()
      .then(
        (data: {regionHealthReports: RegionServiceStatus[]}) => {
          const summaryData = setSummaryStatusData(data);
          setRegionCategories(summaryData.regionCategories);
          setServiceCategories(summaryData.serviceCategories);
          setServiceRegionStatus(summaryData.serviceRegionStatus);
          setActiveIncidents(Array.from(summaryData.serviceIncidentSet));
          setIsError(false);
          postAnalytics(StatusPage.fetchJSON, EventStatus.Success, {
            httpStatusCode: 200,
          });
        },
        (error) => {
          if (enableHeartbeatDetectionFlag) {
            fetchSecondaryStatusSummary();
          } else {
            postAnalytics(StatusPage.fetchJSON, EventStatus.Failure, {
              httpStatusCode: error?.httpStatusCode,
            });
          }
        },
      )
      .finally(() => {
        setTimeUpdated(new Date().toLocaleString());
        setIsLoading(false);
      });
  };

  const fetchStatusData = async () => {
    const values = await checkHeartbeatHealth();
    heartbeat.current = values;
    if (
      (heartbeat.current.domain === DataPath.Primary ||
        heartbeat.current.domain === DataPath.Secondary) &&
      !(!heartbeat.current.status && showErrorManualFlag)
    ) {
      fetchStatusSummary();
    } else {
      postAnalytics(ErrorPage.load, EventType.PageViewEvent);
      setIsLoading(false);
      setHeartbeatFailure(true && showErrorManualFlag);
      updatePageHeader('');
      setTimeUpdated(new Date().toLocaleString());
      setIsError(true);
    }
  };

  useEffect(() => {
    updatePageHeader && updatePageHeader(Pages.LiveStats);
    fetchStatusData();
    postAnalytics(StatusPage.load, EventType.PageViewEvent);
  }, []);

  const handleError = () => {
    postAnalytics(StatusPage.render, EventStatus.Failure);
  };

  return (
    <ErrorBoundary onError={handleError}>
      <MetricsWrapper
        eventName={StatusPage.render}
        eventStatus={EventStatus.Success}>
        <div
          data-testid="status-dashboard-page"
          className="p-0 app d-flex flex-column">
          {activeIncidents &&
            !heartbeatFailure &&
            activeIncidents.map((activeIncidentId: string) => (
              <ActiveIncident
                key={activeIncidentId}
                incidentId={activeIncidentId}
              />
            ))}
          {heartbeatFailure ? (
            <StatusHealthFailure
              consoleAnnouncementURL={consoleAnnouncementURL}
              reload={fetchStatusData}
              timeUpdated={timeUpdated}
            />
          ) : (
            <div className="page-content-container">
              <div className="content-header-top"></div>
              {isLoading ? (
                <div className="loading-spinner">
                  <Spinner animation="border" />
                </div>
              ) : isError ? (
                <div
                  data-testid="status-dashboard-page-error"
                  className="status-error">
                  {t('Incident.statusError')}
                </div>
              ) : (
                <StatusDashboard
                  serviceRegionStatus={serviceRegionStatus}
                  serviceCategories={serviceCategories}
                  regionCategories={regionCategories}
                />
              )}
            </div>
          )}
        </div>
      </MetricsWrapper>
    </ErrorBoundary>
  );
};

export default StatusDashboardContainer;
