import React from 'react';
import {Table} from 'react-bootstrap';
import {TFunction, useTranslation} from 'react-i18next';
import {
  operational,
  serviceDown,
  informational,
  serviceDisruption,
  serviceNotAvailable,
} from '../assets/icons';
import {
  Region,
  Service,
  ServiceCategories,
  ServiceRegionStatus,
  ServiceStatus,
} from '../pages/status-dashboard/StatusDashboardContainer';
import {ClientUrls, Status} from '../constants';
import {Link} from 'react-router-dom';

interface ServiceStatusListProps {
  regions: Region[];
  serviceCategories: ServiceCategories;
  serviceRegionStatus: ServiceRegionStatus;
}
interface SortedService {
  serviceId: NonNullable<string>;
  service: Service;
}

const getIconElement = (tooltip: string, imgSrc: any, incidentId?: string) => {
  if (incidentId) {
    return (
      <Link
        data-testid="incident-history-link"
        to={`${ClientUrls.incidentDetailPage}${incidentId}`}>
        <img
          className="status-icon"
          src={imgSrc}
          alt={tooltip}
          title={tooltip}
        />
      </Link>
    );
  } else {
    return (
      <img className="status-icon" src={imgSrc} alt={tooltip} title={tooltip} />
    );
  }
};

const getCellElement = (
  serviceStatus: ServiceStatus,
  t: TFunction<'translation'>,
) => {
  switch (serviceStatus.status) {
    case Status.NormalPerformance:
      return getIconElement(
        t('Common.operational'),
        operational,
        serviceStatus.incidentId,
      );
    case Status.Informational:
      return getIconElement(
        t('Common.informational'),
        informational,
        serviceStatus.incidentId,
      );
    case Status.ServiceDisruption:
      return getIconElement(
        t('Common.serviceDisruption'),
        serviceDisruption,
        serviceStatus.incidentId,
      );
    case Status.ServiceDown:
      return getIconElement(
        t('Common.serviceDown'),
        serviceDown,
        serviceStatus.incidentId,
      );
    default:
      <div className="status-icon" />;
  }
};

const getStatusElement = (
  serviceStatus: ServiceStatus,
  regionName: string,
  t: TFunction<'translation'>,
) => {
  return (
    <div className="service-val" key={regionName}>
      {serviceStatus && serviceStatus.status ? (
        getCellElement(serviceStatus, t)
      ) : (
        <img
          className="status-icon"
          src={serviceNotAvailable}
          alt={t('Common.serviceNotAvailable')}
          title={t('Common.serviceNotAvailable')}
        />
      )}
    </div>
  );
};

const getSortedServices = (
  services: Record<string, Service>,
): SortedService[] => {
  const sortedServices: SortedService[] = [];
  Object.keys(services).forEach((serviceId: string) => {
    sortedServices.push({
      serviceId: serviceId,
      service: services[serviceId],
    });
  });
  sortedServices.sort((serviceA: SortedService, serviceB: SortedService) =>
    serviceA.service.serviceName.localeCompare(serviceB.service.serviceName),
  );
  return sortedServices;
};

const getSortedServiceCategories = (
  serviceCategories: ServiceCategories,
): string[] => {
  return Object.keys(
    serviceCategories,
  ).sort((serviceCategoryA: string, serviceCategoryB: string) =>
    serviceCategoryA.localeCompare(serviceCategoryB),
  );
};

const ServiceStatusList = (props: ServiceStatusListProps) => {
  const {t} = useTranslation();
  return (
    <>
      <div className="table-container">
        <Table className="status-table">
          <thead>
            <tr className="status-table-region-header">
              <th className="align-middle">
                {t('StatusDashboard.productsServices')}
              </th>
              {props.regions.map((region: Region) => (
                <th
                  data-testid="live-status-region-name"
                  key={region.regionName}
                  className="align-middle">
                  {region.regionName}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {getSortedServiceCategories(props.serviceCategories).map(
              (serviceCategory: string) => (
                <React.Fragment key={serviceCategory}>
                  <tr className="service-category">
                    <th className="category">{serviceCategory}</th>
                    <td colSpan={props.regions.length}></td>
                  </tr>
                  {getSortedServices(
                    props.serviceCategories[serviceCategory],
                  ).map((sortedService: SortedService) => (
                    <tr
                      key={sortedService.serviceId}
                      style={{backgroundColor: 'white'}}>
                      <th className="left-heading">
                        {sortedService.service.serviceName}{' '}
                      </th>
                      {props.regions.map((region: Region) => (
                        <td
                          key={`${sortedService.serviceId}${region.regionName}`}
                          className="align-middle">
                          {getStatusElement(
                            props.serviceRegionStatus[sortedService.serviceId][
                              region.regionId
                            ],
                            region.regionName,
                            t,
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </React.Fragment>
              ),
            )}
          </tbody>
        </Table>
      </div>
    </>
  );
};
export default ServiceStatusList;
