import {APIUrls} from '../constants';
import {
  getEnableHeartbeatDetectionFlag,
  getHeartbeatThreshold,
  getSecondaryDataPath,
} from '../util/config';
import httpClient, {BASE_API_URL} from './httpClient';

export enum DataPath {
  Primary = 'Primary',
  Secondary = 'Secondary',
  Unknown = '',
  Error = 'Error',
}

export interface HeartbeatStatus {
  status: boolean;
  domain: DataPath;
  basePath: string;
  timeDifference: number;
}

export const getStatus = async () => {
  const heartbeatValues = await getHeartbeatStatus();
  const url = `${heartbeatValues.basePath}/${APIUrls.statusJson}`;
  return httpClient.get(url);
};

export const getSecondaryStatus = async () => {
  const dataPath = getSecondaryDataPath();
  const url = `${dataPath}/${APIUrls.statusJson}`;
  return httpClient.get(url);
};

export const getStatusSummary = async () => {
  const heartbeatValues = await getHeartbeatStatus();
  const url = `${heartbeatValues.basePath}/${APIUrls.statusSummaryJson}`;
  return httpClient.get(url);
};

export const getSecondaryStatusSummary = async () => {
  const dataPath = getSecondaryDataPath();
  const url = `${dataPath}/${APIUrls.statusSummaryJson}`;
  return httpClient.get(url);
};

const checkHeartbeatValidity = async (
  primaryURL: string,
  secondaryURL: string,
  heartbeatHealthy: HeartbeatStatus,
  secondaryBasePath: string,
) => {
  try {
    const [primaryStatus, primaryTimestamp] = await getHeartbeatTimestamp(
      primaryURL,
    );
    heartbeatHealthy.status = primaryStatus as boolean;
    heartbeatHealthy.timeDifference = primaryTimestamp as number;
    if (heartbeatHealthy.status) {
      heartbeatHealthy.domain = DataPath.Primary;
    }
  } catch (error) {
    heartbeatHealthy.status = false;
    heartbeatHealthy.domain = DataPath.Error;
  }
  if (!heartbeatHealthy.status) {
    if (secondaryURL) {
      try {
        // Fall back to secondary path, even if its stale
        const [
          secondaryStatus,
          secondaryTimestamp,
        ] = await getHeartbeatTimestamp(secondaryURL);
        if (
          secondaryStatus ||
          secondaryTimestamp < heartbeatHealthy.timeDifference ||
          heartbeatHealthy.domain === DataPath.Error
        ) {
          heartbeatHealthy.basePath = secondaryBasePath;
          heartbeatHealthy.domain = DataPath.Secondary;
        } else {
          heartbeatHealthy.domain = DataPath.Primary;
        }
      } catch (error) {
        heartbeatHealthy.status = false;
        if (heartbeatHealthy.domain !== DataPath.Error) {
          heartbeatHealthy.domain = DataPath.Primary;
          heartbeatHealthy.basePath = BASE_API_URL;
        }
      }
    }
  }
  return heartbeatHealthy;
};

export const getHeartbeatStatus = async () => {
  const url = `${BASE_API_URL}/${APIUrls.heartbeatJson}`;
  const enableHeartbeatDetectionFlag = getEnableHeartbeatDetectionFlag();
  const secondaryPath = getSecondaryDataPath();
  let secondaryURL = '';
  if (secondaryPath) {
    secondaryURL = `${secondaryPath}/${APIUrls.heartbeatJson}`;
  }
  let heartbeatHealthy: HeartbeatStatus = {
    status: true,
    domain: DataPath.Unknown,
    basePath: BASE_API_URL,
    timeDifference: 0,
  };
  // Handle case for dev, where there will be no heartbeat
  if (process.env.NODE_ENV !== 'production' || !enableHeartbeatDetectionFlag) {
    heartbeatHealthy.domain = DataPath.Primary;
    return heartbeatHealthy;
  }
  heartbeatHealthy = await checkHeartbeatValidity(
    url,
    secondaryURL,
    heartbeatHealthy,
    secondaryPath,
  );
  return heartbeatHealthy;
};

async function getHeartbeatTimestamp(url: string) {
  let healthy = true;
  const heartbeat = await httpClient.get(url).then((data) => {
    if (data) {
      return data ? data['lastUpdatedTime'] : '';
    }
  });
  if (heartbeat) {
    const difference = new Date().getTime() - new Date(heartbeat).getTime();
    const heartbeatThreshold = getHeartbeatThreshold();
    console.log('Heart beat threshold: ' + heartbeatThreshold);
    if (difference > heartbeatThreshold) {
      healthy = false;
    }
    return [healthy, difference];
  }
  return [false, -1];
}
