import React from 'react';
import {IncidentDetailInterface} from './IncidentDetailContainer';
import IncidentUpdateList from '../../components/IncidentUpdateList';

interface IncidentDetailProps {
  incidentDetails: IncidentDetailInterface;
}

const IncidentDetail = (props: IncidentDetailProps) => {
  return (
    <div className="incidents">
      <h2>{props.incidentDetails.title}</h2>
      {props.incidentDetails.incidentPosts && (
        <IncidentUpdateList incidentDetails={props.incidentDetails} />
      )}
    </div>
  );
};

export default IncidentDetail;
