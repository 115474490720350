import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {Links} from '../constants';
import {showBanner} from '../util/config';
import Banner from './Banner';

const Footer = () => {
  const {t} = useTranslation();
  const currentYear = new Date().getFullYear();
  const isBanner = showBanner();
  return (
    <div className="footer" data-testid="app-footer">
      <div className="footer-panel">
        <ul className="footer-list">
          <li>
            <a
              data-testid="terms-of-use"
              className="footer-nav-item-color"
              href={Links.termsOfUse}
              target="_blank"
              rel="noreferrer">
              {t('Footer.terms')}
            </a>
          </li>
          <span className="footer-nav-separater"></span>
          <li>
            <a
              data-testid="privacy"
              className="footer-nav-item-color"
              href={Links.privacy}
              target="_blank"
              rel="noreferrer">
              {t('Footer.privacy')}
            </a>
          </li>
          <span className="footer-nav-separater"></span>
          <li>
            <span
              className="footer-nav-item-color"
              id="teconsent"
              data-testid="teconsent-test"
            />
          </li>
        </ul>
        <div className="d-flex flex-row align-items-center">
          <div data-testid="copyright" className="mr-10">
            <Trans
              i18nKey="Footer.copyright"
              components={[]}
              values={{currentYear}}
            />
          </div>
          <span>
            <i data-testid="contact-us-icon" className="contact-us"></i>
          </span>
        </div>
      </div>
      {isBanner && <Banner />}
    </div>
  );
};

export default Footer;
