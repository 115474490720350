import React from 'react';
import {Row} from 'react-bootstrap';
import {TFunction, useTranslation} from 'react-i18next';
import {
  IncidentDetailInterface,
  IncidentUpdate,
} from '../pages/incident-detail/IncidentDetailContainer';
import {IncidentUpdateStatus} from '../constants';
import {calculateDaysfromToday, getUTCDateTimeString} from '../util/Date';
import '../assets/sass/incidents.scss';
import IncidentImpactList from './IncidentImpactList';

interface IncidentUpdateListProps {
  incidentDetails: IncidentDetailInterface;
}

const getPostedOnText = (
  incidentDetails: IncidentDetailInterface,
  incidentUpdate: IncidentUpdate,
  t: TFunction<'translation'>,
) => {
  const days = incidentDetails.backFilled
    ? calculateDaysfromToday(incidentUpdate.postedTime)
    : calculateDaysfromToday(incidentUpdate.createdTime);
  if (days === 0) {
    return `${t('Incident.today')}. `;
  } else {
    return `${days} ${t('Incident.days')} ${t('Incident.ago')}. `;
  }
};

const IncidentUpdateList = (props: IncidentUpdateListProps) => {
  const {t} = useTranslation();
  return (
    <div>
      {props.incidentDetails.incidentPosts &&
        props.incidentDetails.incidentPosts.map(
          (incidentUpdate: IncidentUpdate) => {
            return (
              <div
                className="incident-update-list"
                data-testid="incident-update-list-item"
                key={incidentUpdate.incidentPostId}>
                <h3>{incidentUpdate.status}</h3>
                <h4>{incidentUpdate.message}</h4>
                <p>
                  {t('Incident.posted')}{' '}
                  {getPostedOnText(props.incidentDetails, incidentUpdate, t)}
                  {props.incidentDetails.backFilled
                    ? getUTCDateTimeString(incidentUpdate.postedTime)
                    : getUTCDateTimeString(incidentUpdate.createdTime)}
                </p>
                {incidentUpdate.incidentStartTime && (
                  <Row>
                    <p className="col-2">{t('Incident.startTime')}:</p>
                    <p className="col-10 incident-detail-text">
                      {getUTCDateTimeString(incidentUpdate.incidentStartTime)}
                    </p>
                  </Row>
                )}
                {incidentUpdate.incidentEndTime && (
                  <Row>
                    <p className="col-2">{t('Incident.endTime')}:</p>
                    <p className="col-10 incident-detail-text">
                      {getUTCDateTimeString(incidentUpdate.incidentEndTime)}
                    </p>
                  </Row>
                )}
                {incidentUpdate.customerImpact &&
                  incidentUpdate.customerImpact !== '-' && (
                    <Row>
                      <p className="col-2">{t('Incident.customerImpact')}:</p>
                      <p className="col-10 incident-detail-text">
                        {incidentUpdate.customerImpact}
                      </p>
                    </Row>
                  )}
                <Row>
                  <p className="col-2">{t('Incident.referenceNumber')}:</p>
                  <p className="col-10 incident-detail-text">
                    {props.incidentDetails.referenceTicketNumber}
                  </p>
                </Row>
                {incidentUpdate.rootCause && incidentUpdate.rootCause !== '-' && (
                  <Row>
                    <p className="col-2">{t('Incident.preliminaryCause')}:</p>
                    <p className="col-10 incident-detail-text">
                      {incidentUpdate.rootCause}
                    </p>
                  </Row>
                )}
                {incidentUpdate.status !== IncidentUpdateStatus.Resolved &&
                  incidentUpdate.incidentImpacts && (
                    <IncidentImpactList
                      incidentImpacts={incidentUpdate.incidentImpacts}
                    />
                  )}
              </div>
            );
          },
        )}
    </div>
  );
};

export default IncidentUpdateList;
