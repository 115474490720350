import React from 'react';
import {useTranslation} from 'react-i18next';
import {
  IncidentSummaryInterface,
  MonthlyIncidentSummaryInterface,
} from '../pages/incident-summary/IncidentSummaryContainer';
import IncidentSummaryListItem from './IncidentSummaryListItem';

interface IncidentSummaryListProps {
  monthlyIncidentSummaries: MonthlyIncidentSummaryInterface[];
}

const IncidentSummaryList = (props: IncidentSummaryListProps) => {
  const {t} = useTranslation();
  return (
    <div>
      {props.monthlyIncidentSummaries &&
        props.monthlyIncidentSummaries.map((monthlyIncidentSummary: any) => (
          <div
            className="incident-summary-monthly-incidents"
            key={monthlyIncidentSummary.month}>
            <h3 data-testid="incident-summary-month">
              {monthlyIncidentSummary.month}
            </h3>
            {monthlyIncidentSummary.incidentSummaries.length ? (
              monthlyIncidentSummary.incidentSummaries.map(
                (incidentSummary: IncidentSummaryInterface) => (
                  <IncidentSummaryListItem
                    key={incidentSummary.incidentId}
                    incidentSummary={incidentSummary}
                  />
                ),
              )
            ) : (
              <h4 data-testid="no-incident-text">
                {t('Incident.noIncidents')}
              </h4>
            )}
          </div>
        ))}
    </div>
  );
};

export default IncidentSummaryList;
