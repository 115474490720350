import React from 'react';
import {useTranslation} from 'react-i18next';
import {Status} from '../constants';
import {
  operational,
  serviceDown,
  informational,
  serviceDisruption,
} from '../assets/icons';
import {FilteredIncidentImpact} from './IncidentImpactList';

interface IncidentImpactListItemProps {
  incidentImpact: FilteredIncidentImpact;
}

const getIcon = (tooltip: string, src: any) => {
  return (
    <img
      data-testid="impact-status-icon"
      className="status-icon mr-10"
      src={src}
      alt={tooltip}
      title={tooltip}
    />
  );
};

const getParsedRegionString = (regions: Set<string>) => {
  const regionValues: string[] = Array.from(regions).sort();
  return regionValues.join(', ');
};

const IncidentImpactListItem = (props: IncidentImpactListItemProps) => {
  const {t} = useTranslation();
  const getStatusElement = (status: string) => {
    switch (status) {
      case Status.NormalPerformance:
        return (
          <div className="incident-impact-status">
            {getIcon(t('Common.operational'), operational)}
            <h5>{t('Common.operational')}</h5>
          </div>
        );
      case Status.Informational:
        return (
          <div className="incident-impact-status">
            {getIcon(t('Common.informational'), informational)}
            <h5>{t('Common.informational')}</h5>
          </div>
        );
      case Status.ServiceDisruption:
        return (
          <div className="incident-impact-status">
            {getIcon(t('Common.serviceDisruption'), serviceDisruption)}
            <h5>{t('Common.serviceDisruption')}</h5>
          </div>
        );
      case Status.ServiceDown:
        return (
          <div className="incident-impact-status">
            {getIcon(t('Common.serviceDown'), serviceDown)}
            <h5>{t('Common.serviceDown')}</h5>
          </div>
        );
      default:
        return <div />;
    }
  };

  return (
    <div className="incident-impact-container">
      {props.incidentImpact && props.incidentImpact.status && (
        <div className="incident-impact-item">
          <div className="mr-10" data-testid="impact-status">
            {getStatusElement(props.incidentImpact.status)}
          </div>
          <h5 className="mr-10">|</h5>
          <h5 className="mr-10" data-testid="service">
            {props.incidentImpact.serviceName}
          </h5>
          <h5 data-testid="regions" className="impact-regions">
            {getParsedRegionString(props.incidentImpact.regions)}
          </h5>
        </div>
      )}
    </div>
  );
};

export default IncidentImpactListItem;
