import React from 'react';
import {useTranslation} from 'react-i18next';
import {ErrorPage, EventType} from '../../constants';
import {postAnalytics} from '../../lib/analytics/postAnalytics';

interface HealthProps {
  consoleAnnouncementURL: string;
  reload: Function;
  timeUpdated: string;
}

export const StatusHealthFailure = (props: HealthProps) => {
  const {t} = useTranslation();
  return (
    <div
      data-testid="status-dashboard-health-check-failure"
      className="healthcheck-error">
      <div className="healthcheck-headerText">
        {t('ErrorPage.statusUnknown')}
      </div>
      <div className="healthcheck-subtext-error">
        {t('ErrorPage.unknownErrorOccurred')}
      </div>
      <div className="healthcheck-subtext">
        {t('ErrorPage.healthCheckSubtext')}
      </div>
      <div
        className="healthcheck-link"
        onClick={() => {
          postAnalytics(`${ErrorPage.reload}`, EventType.ClickEvent);
          props.reload();
        }}>
        {t('ErrorPage.reload')}
      </div>
      <div
        className="healthcheck-link"
        onClick={() => {
          postAnalytics(
            `${ErrorPage.consoleAnnouncements}`,
            EventType.ClickEvent,
          );
          window.open(props.consoleAnnouncementURL);
        }}>
        {t('ErrorPage.announcementsLink')}
      </div>
      <div className="healthcheck-timestamp">{`${t('ErrorPage.timeUpdated')} ${
        props.timeUpdated
      }`}</div>
    </div>
  );
};
