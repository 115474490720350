import React from 'react';
import {useTranslation} from 'react-i18next';
import {logo} from '../assets/images';
import {useHistory} from 'react-router-dom';
import Banner from './Banner';
import {showBanner, needsAuthentication} from '../util/config';
import {loomServices} from 'loom-services';

const Header = () => {
  const {t} = useTranslation();
  const history = useHistory();

  const handleClick = () => {
    history.push('/');
  };

  const handleSignOut = async () => {
    await loomServices.authService.logOut();
  };

  const isBanner = showBanner();
  const isAuthenticated = needsAuthentication();
  return (
    <div id="header" className="header" data-testid="app-header">
      {isBanner && <Banner />}
      <div className="d-flex flex-row header-top align-items-center">
        <div className="header-container">
          <div className="d-flex logo cursor-pointer" onClick={handleClick}>
            <img
              src={logo}
              data-testid="header-oracle-logo"
              alt={t('Header.logoAltText')}
            />
          </div>
          <div
            data-testid="header-logo-text"
            className="logo-text cursor-pointer"
            onClick={handleClick}>
            {t('Header.logoHeading')}
          </div>
          {isAuthenticated && (
            <div className="signout">
              <button
                className="header-link signout-button"
                onClick={handleSignOut}>
                Sign Out
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Header;
