import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {getSecondaryStatus, getStatus} from '../api/statusApi';
import {allOperational} from '../assets/icons';
import {
  APIUrls,
  ClientUrls,
  EventStatus,
  EventType,
  Header,
  Pages,
  Subscription,
  SystemStatus,
} from '../constants';
import {postAnalytics} from '../lib/analytics/postAnalytics';
import {getEnableHeartbeatDetectionFlag} from '../util/config';
import {BASE_API_URL} from '../api/httpClient';

const noIncident = 'none';
export interface SystemStatus {
  page: Page;
  status: PageStatus;
}

interface PageStatus {
  indicator: string;
  description: string;
}

interface Page {
  id: string;
  name: string;
  url: string;
  time_zone: string;
  updated_at: string;
}

interface PageHeaderProps {
  children?: any;
  activePage?: string;
}

const PageHeader = (props: PageHeaderProps) => {
  const {t} = useTranslation();
  const enableHeartbeatDetectionFlag = getEnableHeartbeatDetectionFlag();
  const [headingText, setHeadingText] = useState<string>();
  const [headingImageSource, setHeadingImageSource] = useState<any>();

  const getClassName = (element: string): string => {
    return props.activePage && element === props.activePage
      ? 'header-link-active'
      : 'header-link-inactive';
  };

  const fetchSecondaryStatusData = () => {
    getSecondaryStatus().then(
      (data: SystemStatus) => {
        if (
          data &&
          data.status?.indicator?.toLocaleLowerCase() ===
            noIncident?.toLocaleLowerCase()
        ) {
          setHeadingText(t('Header.statusOperational'));
          setHeadingImageSource(allOperational);
        } else {
          setHeadingText(t('Header.systemStatus'));
          setHeadingImageSource(null);
        }
        postAnalytics(SystemStatus.fetchJSON, EventStatus.Success);
      },
      (error) => {
        console.warn('Error while loading data from secondary path', error);
        postAnalytics(SystemStatus.fetchJSON, EventStatus.Failure);
      },
    );
  };

  const fetchStatusData = () => {
    getStatus().then(
      (data: SystemStatus) => {
        if (
          data &&
          data.status?.indicator?.toLocaleLowerCase() ===
            noIncident?.toLocaleLowerCase()
        ) {
          setHeadingText(t('Header.statusOperational'));
          setHeadingImageSource(allOperational);
        } else {
          setHeadingText(t('Header.systemStatus'));
          setHeadingImageSource(null);
        }
        postAnalytics(SystemStatus.fetchJSON, EventStatus.Success);
      },
      (error) => {
        console.warn('Error while loading data from primary path', error);
        if (enableHeartbeatDetectionFlag) {
          fetchSecondaryStatusData();
        } else {
          postAnalytics(SystemStatus.fetchJSON, EventStatus.Failure);
        }
      },
    );
  };

  const onRSSClick = () => {
    const rssUrl = `${BASE_API_URL}/${APIUrls.rss}`;
    postAnalytics(Subscription.rssClick, EventType.ClickEvent);
    const newTab = window.open(rssUrl, '_blank');
    if (newTab) {
      newTab.focus();
    }
  };

  const onHeaderLinkClick = (link: string) => {
    let eventName =
      link === Pages.LiveStats
        ? Header.liveStatsClick
        : Header.incidentHistoryClick;
    postAnalytics(eventName, EventType.ClickEvent);
  };

  useEffect(() => {
    fetchStatusData();
  }, []);

  return (
    <div className="page-header header-status d-lg-flex flex-lg-row align-items-center justify-content-between">
      <div className="page-header-children">
        {headingImageSource && (
          <img
            className="page-header-icon"
            data-testid="page-header-status-image"
            src={headingImageSource}
            alt=""
          />
        )}
        <span className="all-status-text" data-testid="page-header-status-text">
          {headingText}
        </span>
      </div>
      <div className="header-actions">
        <Link
          onClick={() => onHeaderLinkClick(Pages.LiveStats)}
          className={`${getClassName(Pages.LiveStats)} mr-10`}
          data-testid="live-status-link"
          to={ClientUrls.homePage}>
          {t('Header.viewLiveStatus')}
        </Link>
        <Link
          onClick={() => onHeaderLinkClick(Pages.IncidentHistory)}
          className={`${getClassName(Pages.IncidentHistory)} mr-15`}
          data-testid="incident-history-link"
          to={ClientUrls.incidentHistoryPage}>
          {t('Header.incidentHistory')}
        </Link>
        <button
          data-testid="rss-button"
          className="button-primary"
          onClick={onRSSClick}>
          {t('Subscribe.rss')}
        </button>
      </div>
      {props.children}
    </div>
  );
};

export default PageHeader;
