export enum Status {
  NormalPerformance = 'NormalPerformance',
  Informational = 'Informational',
  ServiceDisruption = 'ServiceDisruption',
  ServiceDown = 'ServiceDown',
  None = 'NONE',
}

export const StatusSeverityOrder = [
  'ServiceDown',
  'ServiceDisruption',
  'Informational',
  'NormalPerformance',
];

export enum IncidentUpdateStatus {
  Resolved = 'Resolved',
  Identified = 'Identified',
  Monitoring = 'Monitoring',
  Investigating = 'Investigating',
}

export enum EventType {
  PageViewEvent = 0,
  ClickEvent = 1,
}

export enum Pages {
  LiveStats = 'Live Stats',
  IncidentHistory = 'Incident History',
  IncidentDetail = 'Incident Detail',
  ErrorPage = 'Error',
}

export enum EventStatus {
  Failure = 0,
  Success = 1,
}
