import React from 'react';
import {Link} from 'react-router-dom';
import {chevronRight} from '../assets/icons';
import '../assets/sass/breadcrumb.scss';

export interface BreadCrumbItem {
  displayName: string;
  href?: string;
}

interface BreadCrumbProps {
  items: BreadCrumbItem[];
}

const BreadCrumb = (props: BreadCrumbProps) => {
  return (
    <div className="bread-crumb">
      {props.items &&
        props.items.map((item: BreadCrumbItem, index: number) => {
          return (
            <div className="bread-crumb-item" key={item.displayName}>
              {item.href ? (
                <Link
                  className="bread-crumb-link mr-10"
                  data-testid="bread-crumb-link"
                  to={item.href}>
                  {item.displayName}
                </Link>
              ) : (
                <div className="mr-10 current-active-breadcrumb" data-testid="bread-crumb-text">
                  {item.displayName}
                </div>
              )}

              {index < props.items.length - 1 && (
                <img className="mr-10" src={chevronRight} alt="chevron-right" />
              )}
            </div>
          );
        })}
    </div>
  );
};

export default BreadCrumb;
