import {loomServices} from 'loom-services';
import {Realm} from 'oci-console-regions';
import React from 'react';
import ReactDOM from 'react-dom';
import {Store, StoreContextProvider} from 'savant-connector';
import apiClients from './apiClients';
import App from './App';
import {SessionMonitor} from './SessionMonitor';
import {
  buildAuthorizationUrl,
  buildConsoleUrl,
  buildIdentityUrl,
  buildLoginUrl,
  ConsoleDefaultRegionMapping,
  getTopLevel,
} from './urlUtils';
import {Region} from 'oci-console-regions';

const storeObj: any = {};
const savantStore: Store = {
  get: (key: string) => storeObj[key],
  put: (key: string, value: any) => (storeObj[key] = value),
};

const getRegionName = (): string => {
  let params = new URLSearchParams(document.location.search);
  return (
    params.get('region') || ConsoleDefaultRegionMapping.get(Realm.OC1) || ''
  );
};

const restoreRouteCallback = () => {
  window.history.pushState(null, '', '/');
};
const regionName = getRegionName();

const authorizationServiceUrl = () => buildAuthorizationUrl(regionName);
const landingUrl = buildConsoleUrl(regionName);
const identityServiceUrl = buildIdentityUrl(regionName);
const loginServiceUrl = buildLoginUrl(regionName);

const log = (realm: string, logLevel: any, message: string) => {
  // Needs to be changed to Lumberjack endpoint for authenticated logs if needed.
  console.log(`ream ${realm} message ${message}`);
};

const sendMetric = (realm: string, name: string, value: number) => {
  // Needs to be changed to T2 endpoint for authenticated metrics if needed.
  console.log(`metric realm ${realm} name ${name} value ${value}`);
};

// Regions for which we will need to ensure authentication.
const authenticatedRegions: string[] = [
  Region.LTN,
  Region.BRS,
  Region.NJA,
  Region.UKB,
];

if (authenticatedRegions.includes(regionName)) {
  loomServices
    .init({
      authConfig: {
        restoreRouteCallback,
        authorizationServiceUrl,
        landingUrl,
        identityServiceUrl,
        loginServiceUrl,
        log,
        sendMetric,
      },
    })
    .then(() => {
      const sessionMonitor = new SessionMonitor(24 * 60 * 60);
      sessionMonitor.start();
      apiClients.initialize({
        store: savantStore,
        fetch: loomServices.authService.getFetchApi(),
        baseApiConfig: {},
        initialRegion: regionName,
        secondLevelDomain: getTopLevel(regionName),
      });
    })
    .then(() => {
      ReactDOM.render(
        <React.StrictMode>
          <StoreContextProvider store={savantStore}>
            <App />
          </StoreContextProvider>
        </React.StrictMode>,
        document.getElementById('root'),
      );
    });
} else {
  ReactDOM.render(
    <React.StrictMode>
      <StoreContextProvider store={savantStore}>
        <App />
      </StoreContextProvider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
}
