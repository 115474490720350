import React, {useEffect, useState} from 'react';

//#region types
export interface Menu {
  id: string;
  name: string;
}

interface MenuBarProps {
  menuList: Menu[];
  selectedMenuId?: string;
  menuContainerClass?: string;
  menuListClass?: string;
  menuItemClass?: string;
  onMenuClick: any;
}

//#endregion

const MenuBar = (props: MenuBarProps) => {
  const [selectedMenuId, setSelectedMenuId] = useState<string>();
  const onMenuClick = (event: any) => {
    if (event.target && event.target.matches('li.menu-item')) {
      let selectedId = event.target.id;
      setSelectedMenuId(selectedId);
      props.onMenuClick(selectedId);
    }
  };
  useEffect(() => {
    if (props.selectedMenuId) {
      setSelectedMenuId(props.selectedMenuId);
    } else if (props.menuList.length > 0) {
      setSelectedMenuId(props.menuList[0].id);
    }
  }, [props]);
  return (
    <div className={`menu-list ${props.menuContainerClass}`}>
      <ul onClick={onMenuClick} className={`${props.menuListClass}`}>
        {props.menuList &&
          props.menuList.length > 0 &&
          props.menuList.map((menu: any) => (
            <li
              data-testid="live-status-menu-item"
              key={menu.id}
              id={menu.id}
              className={`menu-item ${props.menuItemClass} ${
                selectedMenuId === menu.id ? 'active' : ''
              }`}>
              {menu.name}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default MenuBar;
