import React, {useEffect, useState} from 'react';
import {OverlayTrigger, Popover} from 'react-bootstrap';
import MenuBar, {Menu} from '../../components/MenuBar';
import {
  Region,
  RegionCategories,
  ServiceCategories,
  ServiceRegionStatus,
} from './StatusDashboardContainer';
import ServiceStatusList from '../../components/ServiceStatusList';
import {EventType, StatusPage} from '../../constants';
import {postAnalytics} from '../../lib/analytics/postAnalytics';
import {legendIcon} from '../../assets/icons';
import StatusLegend from '../../components/StatusLegend';
import {
  getDefaultGeographicArea,
  getRegionCategorySortOrder,
} from '../../util/config';
import {useTranslation} from 'react-i18next';

//#region types
interface StatusDashboardProps {
  regionCategories: RegionCategories;
  serviceCategories: ServiceCategories;
  serviceRegionStatus: ServiceRegionStatus;
}
//#endregion

const StatusDashboard = (props: StatusDashboardProps) => {
  const {t} = useTranslation();
  const [selectedRegionCategory, setSelectedRegionCategory] = useState<string>(
    '',
  );
  const [selectedRegions, setSelectedRegions] = useState<Region[]>([]);
  const [regionCategoryList, setRegionCategoryList] = useState<Menu[]>([]);

  const onMenuClick = (selectedRegionCategoryId: string) => {
    setSelectedRegionCategory(selectedRegionCategoryId);
    onRegionCategoryChange(selectedRegionCategoryId);
    postAnalytics(
      `${StatusPage.regionCategoryClick} - ${selectedRegionCategoryId}`,
      EventType.ClickEvent,
    );
  };
  const onRegionCategoryChange = (selectedRegionCategoryId?: string) => {
    let id = selectedRegionCategoryId
      ? selectedRegionCategoryId
      : selectedRegionCategory;
    const regions = props.regionCategories[id];
    if (regions) {
      setSelectedRegions(regions);
    }
  };
  useEffect(() => {
    if (props.regionCategories) {
      const menuList: Menu[] = Object.keys(props.regionCategories).map(
        (regCatg) => ({
          id: regCatg,
          name: regCatg,
        }),
      );
      const regionCategorySortOrder = getRegionCategorySortOrder();
      menuList.sort(
        (regionCategory1, regionCategory2) =>
          regionCategorySortOrder.indexOf(
            regionCategory1.id ? regionCategory1.id : '',
          ) -
            regionCategorySortOrder.indexOf(
              regionCategory2.id ? regionCategory2.id : '',
            ) || regionCategory1.id.localeCompare(regionCategory2.id),
      );

      if (menuList.length > 0) {
        const defaultGeographicArea = getDefaultGeographicArea();
        const defaultValue =
          defaultGeographicArea &&
          menuList.find((element) => element.name === defaultGeographicArea);

        const regionCategoryId = defaultValue
          ? defaultValue.id
          : menuList[0].id;

        setSelectedRegionCategory(regionCategoryId);
        setRegionCategoryList(menuList);
        onRegionCategoryChange(regionCategoryId);
      }
    }
  }, [props]);

  return props.regionCategories &&
    Object.keys(props.regionCategories).length > 0 &&
    props.serviceCategories &&
    Object.keys(props.serviceCategories).length > 0 &&
    props.serviceRegionStatus &&
    Object.keys(props.serviceRegionStatus).length > 0 ? (
    <div className="status-dashboard">
      {props.regionCategories &&
        Object.keys(props.regionCategories).length > 0 && (
          <div className="region-catg-list">
            <MenuBar
              menuList={regionCategoryList}
              selectedMenuId={selectedRegionCategory}
              onMenuClick={onMenuClick}
            />
            <OverlayTrigger
              trigger={['hover', 'click']}
              transition={false}
              placement="left-start"
              overlay={
                <Popover className="legend-popover" id="popover-basic">
                  <StatusLegend />
                </Popover>
              }>
              {({ref, ...triggerHandler}) => (
                <img
                  {...triggerHandler}
                  ref={ref}
                  src={legendIcon}
                  alt="legend"
                />
              )}
            </OverlayTrigger>
          </div>
        )}
      <div className="horizontal-Separator"></div>
      <ServiceStatusList
        regions={selectedRegions}
        serviceCategories={props.serviceCategories}
        serviceRegionStatus={props.serviceRegionStatus}
      />
    </div>
  ) : (
    <div data-testid="status-data-not-found" className="status-error">
      {t('Incident.statusDataNotFound')}
    </div>
  );
};

export default StatusDashboard;
