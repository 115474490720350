import * as React from 'react';
import {postAnalytics} from '../lib/analytics/postAnalytics';

interface MetricsProps {
  eventName: string;
  eventStatus: number;
}

export class MetricsWrapper extends React.Component<MetricsProps> {
  constructor(props: MetricsProps) {
    super(props);
  }

  public componentDidMount(): void {
    const {eventName, eventStatus} = this.props;
    postAnalytics(eventName, eventStatus);
  }

  public render(): JSX.Element | JSX.Element[] {
    return <>{this.props.children}</>;
  }
}
