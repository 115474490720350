import React, {useState} from 'react';
import {BrowserRouter, HashRouter, Route, Switch} from 'react-router-dom';
import {Container} from 'react-bootstrap';
import Header from './components/Header';
import Footer from './components/Footer';
import PageHeader from './components/PageHeader';
import StatusDashboardContainer from './pages/status-dashboard/StatusDashboardContainer';
import IncidentSummaryContainer from './pages/incident-summary/IncidentSummaryContainer';
import IncidentDetailContainer from './pages/incident-detail/IncidentDetailContainer';
import ErrorPageContainer from './pages/error-page/ErrorPageContainer';

interface AppRouterProps {
  isHashRouting: boolean;
}

const AppContainer = () => {
  const [activePage, setActivePage] = useState('');
  return (
    <Container fluid className="p-0 app d-flex flex-column min-vh-100">
      <Header />
      <main>
        {activePage !== '' && <PageHeader activePage={activePage} />}
        <Switch>
          <Route
            exact
            path="/"
            render={(routeProps) => (
              <StatusDashboardContainer
                {...routeProps}
                updatePageHeader={setActivePage}
              />
            )}
          />
          <Route
            path="/history"
            exact
            render={(routeProps) => (
              <IncidentSummaryContainer
                {...routeProps}
                updatePageHeader={setActivePage}
              />
            )}
          />
          <Route
            path="/incidents/:incidentId"
            exact
            render={(routeProps) => (
              <IncidentDetailContainer
                {...routeProps}
                updatePageHeader={setActivePage}
              />
            )}
          />
          <Route
            render={(routeProps) => (
              <ErrorPageContainer
                {...routeProps}
                updatePageHeader={setActivePage}
              />
            )}
          />
        </Switch>
      </main>
      <Footer />
    </Container>
  );
};

export const AppRouter = (props: AppRouterProps) => {
  return props.isHashRouting ? (
    <HashRouter>
      <AppContainer />
    </HashRouter>
  ) : (
    <BrowserRouter>
      <AppContainer />
    </BrowserRouter>
  );
};
