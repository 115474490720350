import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import '../../assets/sass/errorPage.scss';
import {EventStatus, Links} from '../../constants';
import {EventType, ErrorPage} from '../../constants';
import {postAnalytics} from '../../lib/analytics/postAnalytics';
import {MetricsWrapper} from '../../components/MetricsWrapper';
import ErrorBoundary from '../../components/ErrorBoundary';

const ErrorPageContainer = ({
  updatePageHeader,
  errorCode,
}: {
  updatePageHeader?: any;
  errorCode?: any;
}) => {
  const {t} = useTranslation();

  const onClickCopy = () => {
    errorCode && navigator.clipboard.writeText(errorCode);
    postAnalytics(ErrorPage.copyClick, EventType.ClickEvent);
  };

  const onTryAgainClick = () => {
    postAnalytics(ErrorPage.tryAgainClick, EventType.ClickEvent);
  };

  const onContactSupportClick = () => {
    postAnalytics(ErrorPage.contactSupportClick, EventType.ClickEvent);
  };

  useEffect(() => {
    updatePageHeader && updatePageHeader('');
    postAnalytics(ErrorPage.load, EventType.PageViewEvent);
  }, [updatePageHeader]);

  const handleError = () => {
    postAnalytics(ErrorPage.render, EventStatus.Failure);
  };

  return (
    <ErrorBoundary onError={handleError}>
      <MetricsWrapper
        eventName={ErrorPage.render}
        eventStatus={EventStatus.Success}>
        <div
          data-testid="error-page"
          className="app d-flex flex-column error-page">
          <h1>{t('ErrorPage.404')}</h1>
          <h2>{t('ErrorPage.notFound')}</h2>
          <h3>{t('ErrorPage.resourceNotFound')}</h3>
          {errorCode && (
            <div className="error-code-section">
              <p>
                {t('ErrorPage.errorCode')}: {errorCode}
              </p>
              <button
                data-testid="error-copy-button"
                className="error-copy-button"
                onClick={onClickCopy}>
                {t('ErrorPage.copy')}
              </button>
            </div>
          )}
          <div className="error-page-link-section">
            <Link
              className="error-page-link"
              data-testid="try-again-link"
              onClick={onTryAgainClick}
              to={window.location.pathname}>
              {t('ErrorPage.tryAgain')}
            </Link>
            <a
              className="error-page-link"
              data-testid="contact-support-link"
              onClick={onContactSupportClick}
              href={Links.contact}
              rel="noreferrer"
              target="_blank">
              {t('ErrorPage.contactSupport')}
            </a>
          </div>
        </div>
      </MetricsWrapper>
    </ErrorBoundary>
  );
};

export default ErrorPageContainer;
