const LinkBaseUrl = 'https://www.oracle.com';
const incidentSummariesBaseUrl = 'incident-summary';
const incidentDetailsBaseUrl = 'incidents';
export const Links = {
  termsOfUse: `${LinkBaseUrl}/legal/terms.html`,
  privacy: `${LinkBaseUrl}/legal/privacy/`,
  contact: `${LinkBaseUrl}/support/support-options.html`,
};
export const ClientUrls = {
  homePage: '/',
  incidentHistoryPage: '/history',
  incidentDetailPage: '/incidents/',
};
export const APIUrls = {
  statusJson: 'status.json',
  statusSummaryJson: 'components_v2.json',
  heartbeatJson: 'heartbeat.json',
  incidentSummaryJson: `${incidentSummariesBaseUrl}/`,
  incidentDetailJson: `${incidentDetailsBaseUrl}/`,
  rss: 'incident-summary.rss',
};
