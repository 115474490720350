import React from 'react';
import {useTranslation} from 'react-i18next';
import {
  operational,
  informational,
  serviceDisruption,
  serviceDown,
  serviceNotAvailable,
} from '../assets/icons';

const StatusLegend = () => {
  const {t} = useTranslation();
  return (
    <div className="legend">
      <div className="d-flex flex-row align-items-center">
        <img
          className="status-icon mr-10"
          src={operational}
          alt={t('Common.normal')}
        />
        <div className="legend-text">{t('Common.normal')}</div>
      </div>
      <div className="d-flex flex-row align-items-center">
        <img
          className="status-icon mr-10"
          src={informational}
          alt={t('Common.informational')}
        />
        <div className="legend-text">{t('Common.informational')}</div>
      </div>
      <div className="d-flex flex-row align-items-center">
        <img
          className="status-icon mr-10"
          src={serviceDisruption}
          alt={t('Common.serviceDisruption')}
        />
        <div className="legend-text">{t('Common.serviceDisruption')}</div>
      </div>
      <div className="d-flex flex-row align-items-center">
        <img
          className="status-icon mr-10"
          src={serviceDown}
          alt={t('Common.serviceDown')}
        />
        <div className="legend-text">{t('Common.serviceDown')}</div>
      </div>
      <div className="d-flex flex-row align-items-center">
        <img
          className="status-icon mr-10"
          src={serviceNotAvailable}
          alt={t('Common.serviceNotAvailable')}
        />
        <div className="legend-text">{t('Common.serviceNotAvailable')}</div>
      </div>
    </div>
  );
};

export default StatusLegend;
