export const getDataPath = () => {
  const config = (window as {[key: string]: any})['runTimeConfig'];
  if (config && config.dataPath) {
    return config.dataPath;
  }
  return '';
};

export const getSecondaryDataPath = () => {
  const config = (window as {[key: string]: any})['runTimeConfig'];
  if (config && config.secondaryDataPath) {
    return config.secondaryDataPath;
  }
  return '';
};

export const getAnnouncementsURL = () => {
  const config = (window as {[key: string]: any})['runTimeConfig'];
  if (config && config.consoleAnnouncements) {
    return config.consoleAnnouncements;
  }
  return '';
};

export const isAnalyticsEnabled = () => {
  const config = (window as {[key: string]: any})['runTimeConfig'];
  if (config) {
    return config.isAnalytics;
  }
  return false;
};

export const isHashRoutingEnabled = () => {
  const config = (window as {[key: string]: any})['runTimeConfig'];
  if (config) {
    return config.isHashRouting;
  }
  return false;
};

export const showBanner = () => {
  const config = (window as {[key: string]: any})['runTimeConfig'];
  if (config) {
    return config.showBanner;
  }
  return false;
};

export const needsAuthentication = () => {
  const config = (window as {[key: string]: any})['runTimeConfig'];
  if (config) {
    return config.needsAuthentication;
  }
  return false;
};

export const getDefaultGeographicArea = () => {
  const config = (window as {[key: string]: any})['runTimeConfig'];
  if (config) {
    return config.DefaultGeographicAreaName;
  }
  return '';
};

export const getRegionCategorySortOrder = () => {
  const config = (window as {[key: string]: any})['runTimeConfig'];
  if (config && config.regionCategorySortOrder) {
    let regionCategorySortOrder = config.regionCategorySortOrder.split(',');
    regionCategorySortOrder = regionCategorySortOrder.map(
      (regionCategory: string) => regionCategory.trim(),
    );
    return regionCategorySortOrder;
  }
  return [];
};

export const getErrorDisplayFlag = () => {
  const config = (window as {[key: string]: any})['runTimeConfig'];
  if (config) {
    return config.displayErrorPage;
  }
  return false;
};

export const logAppVersion = () => {
  const version = require('../../package.json').version;
  console.log(`App version: ${version}`);
};

export const getAnalyticsUrl = () => {
  const config = (window as {[key: string]: any})['runTimeConfig'];
  if (config) {
    return config.analyticsUrl;
  }
  return '';
};

export const getEnableHeartbeatDetectionFlag = () => {
  const config = (window as {[key: string]: any})['runTimeConfig'];
  if (config) {
    return config.enableHeartbeatDetection;
  }
  return false;
};

export const getHeartbeatThreshold = () => {
  const config = (window as {[key: string]: any})['runTimeConfig'];
  if (config) {
    return config.heartbeatThreshold;
  }
  return 300000;
};

export const getRealm = () => {
  const config = (window as {[key: string]: any})['runTimeConfig'];
  if (config) {
    return config.realm;
  }
  return '';
};
