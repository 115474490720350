import {InfinityTransmitConfigs} from '../../types/Infinity';
import {getAnalyticsUrl, getRealm} from '../../util/config';
import {userOptedOut} from '../truste/truste';

const packageJson = require('../../../package.json');
const infinityInitTagId = 'InfinityStartScript';
const noScriptURL =
  'Infinity analytics is enabled with no configured script URL';

type EventReport = {
  eventName: string;
  eventType: number;
  options?: {httpStatusCode?: number | undefined};
};
const infinityFailCallback = (error: any): void => {
  return console.log(`Failed to track analytics with Infinity ${error}`);
};

const mutationName = 'pageLoadMutation'; // Set name so you can remove it later if desired

declare global {
  interface Window {
    ORA: any;
  }
}

/**
 * Checks if the Infinity Tag should track a user
 */
const shouldTrack = async () => {
  const isUserOptedOut = await userOptedOut();
  return !isUserOptedOut;
};

const transmitConfiguration = (): InfinityTransmitConfigs => {
  return {
    callbacks: {
      fail: infinityFailCallback,
    },
  };
};

let messageQueue: EventReport[] = [];
let processingQueue = false;
let initialized = false;
/**
 * Initializes the infinity tag
 */
export const initializeInfinity = async () => {
  const trackingEnabled = await shouldTrack();
  // shouldTrack checks for user settings if we can track user functions or
  if (!trackingEnabled) {
    // If the infinity tag is left in memory, it will collect some events automatically. This call is a
    // safety check to ensure that its destoryed and prevent any data leakage
    /* Kept this as it will be required to reset the Oracle infinity. Right now there are some issues on resetting it. */
    // unsetORA();
    return;
  }

  // inserts the <src> tag into the browser and configures it
  initializeTag();
};

/**
 * Track an event with infinity analytics
 * @param eventName the origin of the tracking event
 * @param eventType type of event - click, selection etc.
 */
export const postAnalytics = async (
  eventName: string,
  eventType: number,
  options?: {httpStatusCode?: number | undefined},
) => {
  const trackingEnabled = await shouldTrack();
  if (!trackingEnabled) {
    // Tracking disabled.
    /* Kept this as it will be required to reset the Oracle infinity. Right now there are some issues on resetting it. */
    // unsetORA();
    return;
  }

  if (!window.ORA || !window.ORA.click) {
    if (!initialized && !document.getElementById(infinityInitTagId)) {
      initializeTag();
    }
    messageQueue.push({eventName, eventType, options});
    return;
  }

  if (messageQueue.length > 0 && !processingQueue) {
    flushQueue();
  }
  collect(eventName, eventType, options);
};

const flushQueue = (): void => {
  processingQueue = true;
  messageQueue.forEach((message) => {
    collect(message.eventName, message.eventType, message.options);
  });
  messageQueue = [];
  processingQueue = false;
};

const collect = (
  eventName: string,
  eventType: number,
  options?: {httpStatusCode?: number | undefined},
): void => {
  try {
    const realm = getRealm();
    if (window.ORA && window.ORA.click) {
      window.ORA.click({
        config: transmitConfiguration(),
        data: {
          ...baseData(),
          'wt.dl': eventType,
          'wt.ev_nam': eventName,
          'wt.app_ver': packageJson.version,
          'wt.realm': realm,
          'wt.http_status_code': options?.httpStatusCode,
        },
      });
    }
  } catch (error) {
    console.log('Analytic error');
  }
};

const initializeTag = (): void => {
  const scriptUrl = getAnalyticsUrl();
  if (!scriptUrl || typeof scriptUrl !== 'string') {
    console.log(noScriptURL);
    return;
  }
  if (!document.getElementById(infinityInitTagId)) {
    const scriptTag = document.createElement('script');
    scriptTag.async = true;
    scriptTag.type = 'text/javascript';
    scriptTag.src = scriptUrl;
    scriptTag.id = infinityInitTagId;
    document.head.appendChild(scriptTag);
    initialized = true;
  }
  configurator();
};

// Adds mutations: Add some parameters to default events
const configurator = (): void => {
  // Declare Your Parameters to persist
  const mutationsAppDataObject = baseData();

  // Set to true to persist or false to remove existing mutation
  const persistVars = true;

  // Set Mutations
  window.ORA = window.ORA || {productReady: []};
  window.ORA.productReady.push([
    'analytics',
    (function (persistVars, mutationName, mutationsAppDataObject) {
      return () => {
        const applyMutations = (
          persistVars: boolean,
          mutationName: string,
          mutationsAppDataObject: any,
        ) => {
          // If not persisting...
          if (!persistVars) {
            // Clear 'pageLoadMutation' mutation
            window.ORA.analytics.rmMutation(mutationName); // Remove mutatation
          } else {
            const pageLoadMutation = (msg: any) => {
              for (var keyName in mutationsAppDataObject) {
                msg.payload[keyName] = mutationsAppDataObject[keyName];
              }
            };
            window.ORA.analytics.addMutation(
              'pageLoadMutation',
              pageLoadMutation,
            );
          }
        };
        return applyMutations(
          persistVars,
          mutationName,
          mutationsAppDataObject,
        );
      };
    })(persistVars, mutationName, mutationsAppDataObject),
  ]);
};

const baseData = () => {
  const baseData = {
    'wt.app': process.env.REACT_APP_NAME,
    'wt.app_env': process.env.NODE_ENV,
  };
  return baseData;
};

// destroy the OAC object to ensure its not transmitting
const unsetORA = (): void => {
  try {
    if (window.ORA) {
      window.ORA.analytics.rmMutation(mutationName);
    }

    const tag = document.getElementById(infinityInitTagId);
    tag && tag.remove();
    window.ORA && delete window.ORA;
    initialized = false;
  } catch (e) {
    console.log(`error unloading the ORA object`);
  }
};
