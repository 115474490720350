import React, {useEffect} from 'react';
import './assets/sass/app.scss';
import {AppRouter} from './AppRouter';
import './lib/i18n/config';
import {EventType, Application} from '../src/constants';
import {loadTrusteScript} from './lib/truste/truste';
import {postAnalytics} from './lib/analytics/postAnalytics';
import {
  isAnalyticsEnabled,
  isHashRoutingEnabled,
  logAppVersion,
} from './util/config';

const App = () => {
  const isAnalytics = isAnalyticsEnabled();
  const isHashRouting = isHashRoutingEnabled();

  // Log the deployed application version
  logAppVersion();

  useEffect(() => {
    if (isAnalytics) {
      loadTrusteScript();
      postAnalytics(Application.load, EventType.PageViewEvent);
    }
  }, [isAnalytics]);

  return <AppRouter isHashRouting={isHashRouting} />;
};

export default App;
