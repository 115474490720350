import React from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {IncidentSummaryInterface} from '../pages/incident-summary/IncidentSummaryContainer';
import {EventType, IncidentSummaryPage, Pages, ClientUrls} from '../constants';
import {postAnalytics} from '../lib/analytics/postAnalytics';
import {getUTCDateTimeString} from '../util/Date';

interface IncidentSummaryListItemProps {
  incidentSummary: IncidentSummaryInterface;
}

const IncidentSummaryListItem = (props: IncidentSummaryListItemProps) => {
  const {t} = useTranslation();
  const wrapIncidentText = (text: string) => {
    const maxLength = 174;
    return text.length > maxLength
      ? text.substring(0, maxLength) + '...'
      : text;
  };

  const onViewMoreClick = (event: any) => {
    postAnalytics(
      `${IncidentSummaryPage.incidentDetailClick} - ${event.target.id}`,
      EventType.ClickEvent,
    );
  };

  return (
    <div
      className="incident-summary-incident"
      key={props.incidentSummary.title}>
      <h4 className="incident-summary-list-item-heading">
        {props.incidentSummary.title}
      </h4>
      <p>
        {!props.incidentSummary.incidentStartTime &&
          !props.incidentSummary.incidentEndTime &&
          getUTCDateTimeString(props.incidentSummary.firstIncidentPostTime)}
        {props.incidentSummary.incidentStartTime &&
          getUTCDateTimeString(props.incidentSummary.incidentStartTime)}
        {props.incidentSummary.incidentEndTime && ' - '}
        {props.incidentSummary.incidentStartTime &&
          getUTCDateTimeString(props.incidentSummary.incidentEndTime)}
      </p>
      <p>{wrapIncidentText(props.incidentSummary.message)}</p>
      <p>
        <Link
          onClick={onViewMoreClick}
          id={props.incidentSummary.incidentId}
          data-testid="view-more-link"
          className="incident-summary-incident-link"
          to={{
            pathname: `${ClientUrls.incidentDetailPage}${props.incidentSummary.incidentId}`,
            state: {parentPage: Pages.IncidentHistory},
          }}>
          {t('Incident.viewMore')}
        </Link>
      </p>
    </div>
  );
};

export default IncidentSummaryListItem;
