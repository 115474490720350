import {initializeInfinity} from '../analytics/postAnalytics';

const optOutDomain = `oracle.com`;
const trusteIframeId = 'trustarcNoticeFrame';
const trusteScriptId = 'truste-script';
const trusteCmaApi = 'getConsentDecision';
const trusteScriptUrl = `//consent.trustarc.com/notice?domain=${optOutDomain}&c=teconsent&text=true&pcookie&gtm=1`;

export let loadTrusteScriptPromise: Promise<void>;

export async function loadTrusteScript(): Promise<void> {
  // check if the trustarc script already exist
  const trustarcScript = document.getElementsByClassName(trusteScriptId)[0];

  if (trustarcScript) {
    loadTrusteScriptPromise = Promise.resolve();
    return loadTrusteScriptPromise;
  }

  loadTrusteScriptPromise = new Promise((resolve) => {
    const scriptTag = document.createElement('script');
    scriptTag.type = 'text/javascript';
    scriptTag.src = trusteScriptUrl;
    scriptTag.id = trusteScriptId;
    scriptTag.className = trusteScriptId;
    scriptTag.onload = () => {
      const iframe = document.getElementById(trusteIframeId);
      if (!iframe) {
        return resolve();
      }
      iframe.onload = () => {
        resolve();
      };
      iframe.onerror = () => {
        console.log('Error loading trustarc iframe. ');
        // still resolve the promise even when the script fails to load as we want to continue loading app
        resolve();
      };
      successCallback();
    };
    scriptTag.onerror = () => {
      console.log('Error loading trusarc script tag. ');
      // still resolve the promise even when the script fails to load as we want to continue loading app
      resolve();
    };
    document.body.appendChild(scriptTag);
  });

  return loadTrusteScriptPromise;
}

export const userOptedOut = async () => {
  // TRUSTe script is included by the website footer. If it is present, use it to determine whether
  // the user has opted out of tracking.
  const truste = (window as any).truste;
  if (truste) {
    try {
      const consent = await truste.cma.callApi(trusteCmaApi, optOutDomain)
        .consentDecision;
      return consent === 1; // 1 Application cookie, 2 Functional Cookie
    } catch (e) {
      const message =
        (e && e.message) ||
        'Could not get cookie consent decision using third party script';
      console.warn(message);
    }
  }

  return true;
};

const maxRetry = 5;
let retry = 1;

// loading the infinity library after the truste script get loaded
const successCallback = () => {
  if (retry <= maxRetry) {
    if ((window as any).truste && (window as any).truste.cma) {
      initializeInfinity();
      retry = 1;
    } else {
      setTimeout(() => {
        retry++;
        successCallback();
      }, 500);
    }
  } else {
    retry = 1;
  }
};
