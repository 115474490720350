import {loomServices} from 'loom-services';
import {getDataPath, needsAuthentication} from '../util/config';

const dataPath = getDataPath();
const isAuthenticated = needsAuthentication();
export const BASE_API_URL = `${dataPath}`;

const fetchRetry: any = async (
  url: string,
  triesLeft: number,
  fetchOptions = {},
) => {
  async function onError(err: Error) {
    triesLeft = triesLeft - 1;
    if (triesLeft < 0) {
      throw err;
    }
    const response = await fetchRetry(url, triesLeft, fetchOptions);
    return response;
  }
  try {
    return fetch(url, fetchOptions);
  } catch (err_1) {
    return await onError(err_1);
  }
};

const httpClient = {
  get: async (endpoint: string, customConfig = {}, retryCounter = 1) => {
    const config = {
      method: 'GET',
      ...customConfig,
    };
    let response;
    if (isAuthenticated) {
      const authFetch = loomServices.authService.getFetchApi();
      response = await authFetch(`${endpoint}`, config);
    } else {
      response = await fetchRetry(`${endpoint}`, retryCounter, config);
    }
    if (response.ok) {
      return await response.json();
    } else {
      const errorMessage = await response.text();
      return Promise.reject({
        error: new Error(errorMessage),
        httpStatusCode: response.status,
      });
    }
  },
};

export default httpClient;
