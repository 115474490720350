import {IdentityApi} from './gen/clients/identity-control-plane-api-client';
import {createSavantClients} from 'savant-connector';
const VERSION_IDENTITY = '20160918';

const [clients, initialize] = createSavantClients({
  identityApi: {
    class: IdentityApi,
    configKey: 'identity',
    version: VERSION_IDENTITY,
    overrideEndpoint: process.env.IDENTITY_HOST || undefined,
  },
});

const exports = {
  ...clients,
  initialize,
};

export default exports;
