import * as React from 'react';

interface Props {
  /**
   * A function that's called when the error is triggered. Can be used to react to the event in the wrapping
   * application.
   */
  onError: (error: Error, info: React.ErrorInfo) => void;
}

class ErrorBoundaryInternal extends React.Component<Props> {
  public constructor(props: Props) {
    super(props);
  }

  /**
   * Catch errors that are thrown from rendering, lifecycle methods or constructors of **all child elements**. This
   * calls the provided onError function to let the user handle the implementation. This will also cause the component
   * to re-render causing the fallback function to also get called.
   *
   * @param error - The error that was thrown.
   * @param errorInfo - Info about the error thrown.
   */
  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    this.props.onError(error, errorInfo);
  }

  public render(): any {
    return this.props.children;
  }
}

/**
 * A wrapper designed to catch errors that are thrown from rendering, lifecycle methods or constructors of
 * **all child elements**. This component provides a way to catch these errors and render an alternate interface via
 * fallback() in the event of an error.
 *
 * You can read more on how this works here: https://reactjs.org/docs/react-component.html#componentdidcatch
 */
const ErrorBoundary: React.FC<Props> = (props) => (
  <ErrorBoundaryInternal {...props} />
);
export default ErrorBoundary;
